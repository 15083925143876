import React,{useState} from "react";
import { Field } from "formik";


export const PolishLabFancy =({formik,labFancyData,language}) =>{
    const [polishData,setPolishData] =useState(labFancyData.length !== 0 ? labFancyData[0].lab_polish : [])

    // const PolishData = ([
    //     {
    //         id: 1,
    //         polish:"ID"
    //     },
    //     {
    //         id: 2,
    //         polish:"EX"
    //     },
    //     {
    //         id: 3,
    //         polish:"VG"
    //     },
    //     {
    //         id: 4,
    //         polish:"GD"
    //     },
    //     {
    //         id: 5,
    //         polish:"FR"
    //     },
    //     {
    //         id: 6,
    //         polish:"PR"
    //     }
    // ]);

    const newPolishData =polishData.length!==0 ? polishData.split(',').map((value,index) => {
        return {
            id: index,
            polish:value,
        }
    }):null

    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_FINISH_POLISH}</div>
                <div className="col-12" >
                    {newPolishData ?newPolishData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Polish" id={`${item.polish}_polish`} value={item.polish}/>
                            <div className="wClarityBox selectBox">
                                <span>{item.polish}</span>
                            </div>
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
}