import React, {  useState } from "react";
import { Field } from "formik";
import {toAbsoluteUrl} from "../../../../../_helpers";
import SVG from "react-inlinesvg";
import {OverlayTrigger,Tooltip} from "react-bootstrap";

export const ShapeFancy = ({naturalFancyData,language}) => {
    const [shape] = useState( naturalFancyData.length !== 0 && naturalFancyData[0].shape !== null ? naturalFancyData[0].shape : [] );
   
    const newShapeData =shape.length!==0?shape.split(',').map((value,index) => {
        return {
            id: index,
            shape:value,
            multilingualshape:language[`${value.toUpperCase()}`]
        }
    }):null
   
    return (
        <>
        
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3 text-center">{language.CCMODE_THEME1_SEARCHDIAMOND_SHAPE}</div>
                <div className="col-12 text-center">
                    {newShapeData ? newShapeData.map((item) => (
                        <OverlayTrigger placement="top" key={item.id} overlay={<Tooltip id="tooltip" >{item.multilingualshape}</Tooltip>} >
                            <label className="search_btn_check">
                                <Field type="checkbox" name="Shape" id={`${item.shape}_shape`} value={item.shape}/>
                                {/* <span></span> */}
                                <div className="shapeBox selectBox">
                                    <SVG src={toAbsoluteUrl(`/media/shape/svg_shape/${item.shape.toLowerCase()}.svg`)} />
                                    <br/>
                                    {/* <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip" >{item.multilingualshape}</Tooltip>} > */}
                                        <span className="text-uppercase text-truncate">{item.multilingualshape}</span>
                                    {/* </OverlayTrigger> */}
                                </div>
                            </label>
                        </OverlayTrigger>
                    )):null}
                </div>
            </div>
        </>
    );
};
