import React, { Suspense } from "react";
import { Redirect, Switch, } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import "../assets/css/BackendStyle.css"
import { SearchResultTheme1,SearchDiamondTheme1,GridCardTheme1} from "../_metronic/_partials/theme1";
import { SearchResultTheme2,SearchDiamondTheme2,GridCardTheme2 } from "../_metronic/_partials/theme2";
import { SearchResultTheme3,SearchDiamondTheme3,GridCardTheme3,ProductDetailMainTheme3 } from "../_metronic/_partials/theme3";
import { Loader } from "../_metronic/_partials/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function BasePage({theme,user,ruleData,naturalData,naturalFancyData,labData,labFancyData,language,themeData,ipData}) {
 
  return (
    <>
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/searchdiamond" />  
            {/* new */}
            {theme === 'theme1' ? 
                <>
                  {/* theme1  */}
                  <ContentRoute path="/search-result" component={() => <SearchResultTheme1 language={language} themeData={themeData} ipData={ipData}/>} />
                  <ContentRoute path="/grid" component={() => <GridCardTheme1 language={language} themeData={themeData} ipData={ipData}/>} />
                  <ContentRoute path="/details" component={() => <ProductDetailMainTheme3 language={language} themeData={themeData} ipData={ipData}/>} />
                  <ContentRoute path="/searchdiamond" component={()=><SearchDiamondTheme1 ruleData={ruleData} user={user} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} language={language} themeData={themeData} ipData={ipData}/>}/>
                </>
            : theme === 'theme2' ? 
              <>
                {/* theme2  */}
                <ContentRoute path="/search-result" component={() => <SearchResultTheme2 language={language}  themeData={themeData} ipData={ipData}/>} />
                <ContentRoute path="/grid" component={GridCardTheme2} />
                <ContentRoute path="/details" component={() => <ProductDetailMainTheme3 language={language}  themeData={themeData} ipData={ipData}/>} />
                <ContentRoute path="/searchdiamond" component={()=><SearchDiamondTheme2 ruleData={ruleData} user={user} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} language={language} themeData={themeData} ipData={ipData} />} />
              </> 
            : theme === 'theme3' ?
              <>
                {/* theme3  */}
                <ContentRoute path="/search-result" component={SearchResultTheme3} />
                <ContentRoute path="/grid" component={GridCardTheme3} />
                <ContentRoute path="/details" component={() => <ProductDetailMainTheme3 language={language}  themeData={themeData} ipData={ipData}/>} />
                <ContentRoute path="/searchdiamond" component={()=><SearchDiamondTheme3 ruleData={ruleData} user={user} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} language={language} themeData={themeData} ipData={ipData} />} />
              </> 
            :
                <>
                  <ContentRoute path="/searchdiamond" component={Loader} />
                </>
              }
            <Redirect to="error/error-v1" />
          </Switch>
        </Suspense>
        <ToastContainer />
    </>

  );
}
