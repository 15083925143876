import React from "react";
import { useFormik } from "formik";
import { TextField,makeStyles  } from "@material-ui/core";
// import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
// import styles from "./css/style.module.css"
import axios from "axios"
import {LOGIN} from "./../../../../env_config"
import {SetCookies} from "../../../../_metronic/_helpers/Cookies";

const useStyles = makeStyles(theme => ({

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    color:"#fff"
  },
  
}));

function Login(props) {
  const classes = useStyles();
  const { intl } = props;
  // const [loading, setLoading] = useState(false);
  const [ipData, setIpdata] = React.useState([]);

  React.useEffect(() => {
  axios({
    method: 'GET',
    // url: "http://ip-api.com/json/?fields=status,country,region,regionName,city,district,zip,isp,mobile,proxy,hosting,query",
    url:"https://api.ipify.org",
    // headers: { 
    //     'Access-Control-Allow-Origin': '*', 
    //     'Content-Type': 'application/json'
    //   },
    })
    .then(function (res) {
        setIpdata({query:res.data})
    })
    .catch((res) => {
    });
  },[])
  
  const initialValues = {
    mail: "",
    password: "",
    JsonData: ipData
  };
  // const LoginSchema = Yup.object().shape({
  //   mail: Yup.string()
  //     .email("Wrong email format")
  //     .min(3, "Minimum 3 symbols")
  //     .max(50, "Maximum 50 symbols")
  //     .required(
  //       intl.formatMessage({
  //         id: "AUTH.VALIDATION.REQUIRED_FIELD",
  //       })
  //     ),
  //   password: Yup.string()
  //     .required(
  //       intl.formatMessage({
  //         id: "AUTH.VALIDATION.REQUIRED_FIELD",
  //       })
  //     ),
  // });

  // const enableLoading = () => {
  //   setLoading(true);
  // };

  // const disableLoading = () => {
  //   setLoading(false);
  // };

  // const getInputClasses = (fieldname) => {
  //   if (formik.touched[fieldname] && formik.errors[fieldname]) {
  //     return "is-invalid";
  //   }

  //   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
  //     return "is-valid";
  //   }

  //   return "";
  // };
  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
   // validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // enableLoading();
      setTimeout(() => {
        axios({
          method: 'POST',
          url: LOGIN,
          headers: { 
              'Access-Control-Allow-Origin': '*', 
              'Content-Type': 'application/json'
            },
          data: values,
          // withCredentials:true
        })
        .then(function (res) {
            // disableLoading();
            if (res && res.data ) {
              if(res.data.msg){
                setSubmitting(false);
            setStatus(res.data.msg)
            return;
              }
              const user = JSON.stringify(res.data.Customer[0])
              // const currencyData = JSON.stringify(res.data.Customer[0])
              const accessToken =res.data.accessToken
              props.login(accessToken);
              window.localStorage.setItem("userData", user)
              window.localStorage.setItem("C_id", -1)
              SetCookies("resData",user)
              SetCookies("accessToken",res.data.accessToken)
              props.setUser(user);
              // window.localStorage.setItem("userCurrency", currencyData)
              setSubmitting(false);
            }
          })
          .catch((res) => {
            // disableLoading();
            setSubmitting(false);
            window.localStorage.setItem("userData", '')
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <div  id="kt_login_signin_form">
      <div className="container">
        {/* begin::Head */}
        <div className="text-center mb-8">
          <p className="text-muted font-weight-bold">
          </p>
        </div>
        {/* end::Head */}
        <div >
          {/*begin::Form*/}
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
            autoComplete="off"
          >
            {formik.status ? (
              <div className="mb-10 login-alert alert alert-custom alert-light-danger alert-dismissible py-3">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            ) : (
              <div className="">
                {/* <div className="alert-text">
                  Use account <strong>admin@demo.com</strong> and password{" "}
                  <strong>demo</strong> to continue.
                </div> */}
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="mb-5 newlogin login fv-plugins-icon-container d-flex justify-content-center">
                  <TextField 
                    label="Email"
                    type="email"
                    className={classes.textField}
                    name="mail"
                    id="mail"
                    autoComplete="false"
                    // {...formik.getFieldProps("mail")}
                    color="secondary"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mail}
                  //  error={formik.touched.mail && Boolean(formik.errors.mail)}
                    helperText={formik.touched.mail && formik.errors.mail ? formik.errors.mail : null} 
                    fullWidth
                  />
                  {/* {formik.touched.mail && formik.errors.mail ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.mail}</div>
                    </div>
                  ) : null} */}
                </div>
              </div>
              <div className="col-12">
                <div className="mb-5 newlogin fv-plugins-icon-container d-flex justify-content-center">
                  <TextField 
                    label="Password"
                    type="password"
                    className={classes.textField}
                    name="password"
                    autoComplete="false"
                    color="secondary"
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    value={formik.values.password}
                  //  error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password ? formik.errors.password : null} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                  />
                  {/* {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                  ) : null} */}
                </div>
              </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="sum_tab text-center">
                      <button  type="submit"  disabled={formik.isSubmitting} >
                        <span aria-hidden="true">
                          <span ></span>
                        </span>
                        <span>Login</span>
                      </button>
                        {/* <button
                          type="submit"
                          className={`submit btn btn-success px-10 py-3 shadow-sm my-4`}
                          disabled={formik.isSubmitting} 
                        >
                          Login
                          {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button> */}
                    </div>
                </div>
            </div>
          </form>
          {/*end::Form*/}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
