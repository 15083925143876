import React,{useState} from "react";
import { Field } from "formik";



export const ClarityLab =({formik,labData,language}) =>{
    const [clarityData,setClarityData] =useState(labData.length !== 0 && labData[0].lab_clarity !== null ? labData[0].lab_clarity : [])
    // const ClarityData = ([
    //     {
    //         id: 1,
    //         clarity:"FL"
    //     },
    //     {
    //         id: 2,
    //         clarity:"IF"
    //     },
    //     {
    //         id: 3,
    //         clarity:"VVS1"
    //     },
    //     {
    //         id: 4,
    //         clarity:"VVS2"
    //     },
    //     {
    //         id: 5,
    //         clarity:"VS1"
    //     },
    //     {
    //         id: 6,
    //         clarity:"VS2"
    //     },
    //     {
    //         id: 7,
    //         clarity:"SI1"
    //     },
    //     {
    //         id: 8,
    //         clarity:"SI2"
    //     },
    //     {
    //         id: 9,
    //         clarity:"SI3"
    //     },
    //     {
    //         id: 10,
    //         clarity:"I1"
    //     },
    //     {
    //         id: 11,
    //         clarity:"I2"
    //     },
    //     {
    //         id: 12,
    //         clarity:"I3"
    //     }
    // ]);
    
    const newClarityData =clarityData.length!==0 ? clarityData.split(',').map((value,index) => {
        return {
            id: index,
            clarity:value
        }
    }):null

    return (
        <>
            <div className="row mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_CLARITY}</div>
                <div className="col-12" >
                    {newClarityData ?newClarityData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Clarity" id={`${item.clarity}_clarity`} value={item.clarity}/>
                            <div className="wClarityBox selectBox">
                                <span>{item.clarity}</span>
                            </div>
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
}