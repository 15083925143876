import React, { useEffect , useState } from "react";
import { Slider, TextField,makeStyles } from "@material-ui/core";
// import { Field } from "formik";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";

const useStyles = makeStyles({
    TextField: {
      width: 80,
      margin:0
    },
  });

export const TableLabFancy = ({formik,labFancyData,language,location}) => {
    const classes = useStyles();
    const [minTable, setMinTable] = useState(location && location.Formik && typeof (location.Formik.minTable) === "number"?location.Formik.minTable:0);
    const [maxTable, setMaxTable] = useState(location && location.Formik && typeof (location.Formik.maxTable) === "number"?location.Formik.maxTable:100);
    const [tableRangeValue, setTableRangeValue] = useState([location && location.Formik && typeof (location.Formik.minTable) === "number"?location.Formik.minTable:0, location && location.Formik && typeof (location.Formik.maxTable) === "number"?location.Formik.maxTable:100]);
    const minTableRange = 0;
    const maxTableRange = 100;

    useEffect(() => {
        formik.setFieldValue("minTable", minTable);
        formik.setFieldValue("maxTable", maxTable);
    }, []);

    const handleTableRangeChange = (event, newValue) => {
        setMinTable(newValue[0]);
        setMaxTable(newValue[1]);
        setTableRangeValue(newValue);
        // formik.setFieldValue("minTable", newValue[0]);
        // formik.setFieldValue("maxTable", newValue[1]);
    };

    const handleTableRangeChangeCommitted = (event, newValue) => {
        formik.setFieldValue("minTable", newValue[0]);
        formik.setFieldValue("maxTable", newValue[1]);
    };

    return (
        <>
            <div className="row mx-0 mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1__LIST_TABLE} %</div>
                <div className="col-12">
                    <div className="row m-0">
                        <div className="col-md-12">
                            <Slider
                                getAriaLabel={() => "Table Range"}
                                value={tableRangeValue}
                                onChangeCommitted={handleTableRangeChangeCommitted}
                                onChange={handleTableRangeChange}
                                step={0.01}
                                valueLabelDisplay="auto"
                                // getAriaValueText={tableRangeValue}
                                min={minTableRange}
                                max={maxTableRange}
                                color="secondary"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between">
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                name="minTable"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={tableRangeValue[0]}
                                color="secondary"
                                type="number"
                                inputProps={{
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                onChange={(e) => {
                                    setMinTable(Number(e.target.value));
                                    setTableRangeValue([
                                        Number(e.target.value),
                                        tableRangeValue[1],
                                    ]);
                                    formik.setFieldValue("minTable", Number(e.target.value));
                                }}
                            />
                                
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                variant="outlined"
                                name="maxTable"
                                InputLabelProps={{ shrink: true }}
                                value={ tableRangeValue[1]}
                                color="secondary"
                                type="number"
                                inputProps={{
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                onChange={(e) => {
                                    setMaxTable(Number(e.target.value));
                                    setTableRangeValue([
                                        tableRangeValue[0],
                                        Number(e.target.value),
                                    ]);
                                    formik.setFieldValue("maxTable", Number(e.target.value));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
