import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../_helpers";

function CutBlock({ cut }) {
    useEffect(() => {
        const getLeftPercent = (cut) => {
            switch (cut) {
                case "EX":
                    return 8;
                case "VG":
                    return 30;
                case "GD":
                    return 52.5;
                case "FR":
                    return 72.5;
                case "PR":
                    return 92.5;
                default:
                    return 0;
            }
        };

        const cutBlockElements = document.querySelectorAll(
            '[data-toggle="cut-block"]'
        );
        cutBlockElements.forEach((element) => {
            const cutValue = element.getAttribute("data-cut");
            const leftPercent = getLeftPercent(cutValue);
            const width = (window.innerWidth - 30) * (leftPercent / 100);
            if (width > 70) {
                element.style.left = leftPercent + "%";
                element.querySelector(".arrow").style.display = "block";
            }
        });
    }, []);

    return (
        <div className="stone-detail-carat-box">
            <p>
                <b>Cut: {cut}</b>
            </p>
            <div className="point mb-6" data-toggle="cut-block" data-cut={cut}>
                <span className="arrow"></span>
            </div>
            <img
                src={toAbsoluteUrl("/media/General/detail/cut-range.png")}
                className="w-100 mt-4"
                alt="cut Range"
            />
        </div>
    );
}

export default CutBlock;
