import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { CircularProgress } from '@material-ui/core';
export default function VideoIframe({ showVideo, handleClose, src }) {
  const [frameloaded, setFrameLoaded] = useState(false)
  const FrameFunction = () => {
    setFrameLoaded(true)
  }
  return (
    <div>
      <Modal
        show={showVideo}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        
        <Modal.Body>
          {frameloaded ? "" : <div>
            <div style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              marginTop:"50"
            }}>
              <CircularProgress
                      // style={{marginLeft: '40%'}}
              />
              <h6>Please Wait...</h6>
            </div>
          </div>}
          <iframe id="iframedata" title="Video" src={src} height='450px' width='100%' frameborder='0' onLoad={FrameFunction} onError={() => null}></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}