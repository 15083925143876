import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../_helpers";

function CaratBlock({ caratWeight }) {
    useEffect(() => {
        const calculateLeftPercent = (carat) => {
            const p025 = 5.2;
            const p200 = 95.1;
            if (carat < 1) {
                return 3;
            } else if (carat > 10) {
                return 97;
            } else {
                return ((carat - 1) * (p200 - p025)) / (10 - 1) + p025;
            }
        };

        const caratBlockElements = document.querySelectorAll(
            '[data-toggle="carat-block"]'
        );
        caratBlockElements.forEach((element) => {
            const carat = parseFloat(element.getAttribute("data-carat"));
            if (carat) {
                const leftPercent = calculateLeftPercent(carat);
                const width = (window.innerWidth - 30) * (leftPercent / 100);
                if (width < 70) {
                    element.style.marginLeft = "10px";
                    element.querySelector(".arrow").style.left =
                        leftPercent + "%";
                    element.querySelector(".arrow").style.top = "15%";
                } else {
                    element.style.left = leftPercent + "%";
                    element.querySelector(".arrow").style.display = "block";
                }
            }
        });
    }, []);

    return (
        <div className="stone-detail-carat-box">
            <p>
                <b>Carat: {parseFloat(caratWeight).toFixed(2)} Ct.</b>
            </p>
            <div
                className="point mb-6"
                data-toggle="carat-block"
                data-carat={caratWeight}
            >
                <span className="arrow"></span>
            </div>
            <img src={toAbsoluteUrl("/media/General/detail/carat-range.png")} className="w-100 mt-4" alt="carat Range"/>
           
        </div>
    );
}

export default CaratBlock;
