import React, { useEffect , useState } from "react";
import { Field } from "formik";
import {toAbsoluteUrl} from "../../../../../_helpers";
import SVG from "react-inlinesvg";
import {OverlayTrigger,Tooltip} from "react-bootstrap";

export const Shape = ({naturalData,language}) => { 
    const [shape, setShape] = useState( naturalData.length !== 0 && naturalData[0].shape !== null ? naturalData[0].shape : [] );
    // const ShapeData = ([
    //     {
    //         id: 1,
    //         shape:"round"
    //     },
    //     {
    //         id: 2,
    //         shape:"princess"
    //     },
    //     {
    //         id: 3,
    //         shape:"oval"
    //     },
    //     {
    //         id: 4,
    //         shape:"heart"
    //     },
    //     {
    //         id: 5,
    //         shape:"cushion"
    //     },
    //     {
    //         id: 6,
    //         shape:"emerald"
    //     },
    //     {
    //         id: 7,
    //         shape:"marquise"
    //     },
    //     {
    //         id: 8,
    //         shape:"cushion modified"
    //     },
    //     {
    //         id: 9,
    //         shape:"pear"
    //     },
    //     {
    //         id: 10,
    //         shape:"asscher"
    //     },
    //     {
    //         id: 11,
    //         shape:"radiant"
    //     },
    //     {
    //         id: 12,
    //         shape:"square radiant"
    //     },
    //     {
    //         id: 13,
    //         shape:"triangle"
    //     },
    //     {
    //         id: 14,
    //         shape:"trilliant"
    //     },
    // ]);
    const newShapeData =shape.length!==0?shape.split(',').map((value,index) => {
        return {
            id: index,
            shape:value,
            multilingualshape:language[`${value.toUpperCase()}`]
        }
    }):null
    // function reasonGroup (x) {
    //     var y = x.replace(/,\s*$/, "");
    //     var reason = y.split(',').map(function (x, index) {
    //         return <span className="label label-outline-danger label-pill label-inline bg-danger-o-20 font-weight-bold mr-1 text-nowrap" key={index}>{ x }</span>; 
    //     });
    
    //     return <div className="d-flex align-items-center justify-content-center flex-wrap">{reason}</div>;
    // }
    return (
        <>
        
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_SHAPE}</div>
                <div className="col-12">
                    {newShapeData ? newShapeData.map((item) => (
                        <OverlayTrigger placement="top" key={item.id} overlay={<Tooltip id="tooltip" >{item.multilingualshape}</Tooltip>} >
                            <label className="search_btn_check">
                                <Field type="checkbox" name="Shape" id={`${item.shape}_shape`} value={item.shape}/>
                                {/* <span></span> */}
                                <div className="shapeBox selectBox">
                                    <SVG src={toAbsoluteUrl(`/media/shape/svg_shape/${item.shape.toLowerCase()}.svg`)} />
                                </div>
                            </label>
                        </OverlayTrigger>
                    )):null}
                </div>
            </div>
        </>
    );
};
