import React, { useEffect , useState } from "react";
import { Slider, TextField,makeStyles } from "@material-ui/core";
// import { Field } from "formik";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";

const useStyles = makeStyles({
    TextField: {
      width: 80,
      margin:0
    },
  });

export const CaratFancy = ({formik,naturalFancyData,language,location}) => {
    const classes = useStyles();
    const [minCarat, setMinCarat] = useState(location && location.Formik && typeof (location.Formik.minCarat) === "number"?location.Formik.minCarat:naturalFancyData.length !== 0 && naturalFancyData[0].min_carat !== null ? naturalFancyData[0].min_carat : 0);
    const [maxCarat, setMaxCarat] = useState(location && location.Formik && typeof (location.Formik.maxCarat) === "number"?location.Formik.maxCarat:naturalFancyData.length !== 0 && naturalFancyData[0].max_carat !== null ? naturalFancyData[0].max_carat : 30);
    const [caratRangeValue, setCaratRangeValue] = useState([location && location.Formik && typeof (location.Formik.minCarat) === "number"?location.Formik.minCarat:naturalFancyData.length !== 0 && naturalFancyData[0].min_carat !== null ? naturalFancyData[0].min_carat : 0, location && location.Formik && typeof (location.Formik.maxCarat) === "number"?location.Formik.maxCarat:naturalFancyData.length !== 0  && naturalFancyData[0].max_carat !== null ? naturalFancyData[0].max_carat : 30]);
    const minCaratRange = naturalFancyData.length !== 0 && naturalFancyData[0].min_carat !== null  ? naturalFancyData[0].min_carat : 0;
    const maxCaratRange = naturalFancyData.length !== 0  && naturalFancyData[0].max_carat !== null ? naturalFancyData[0].max_carat : 30;

    useEffect(() => {
        formik.setFieldValue("minCarat", minCarat);
        formik.setFieldValue("maxCarat", maxCarat);
    }, []);

    const handleCaratRangeChange = (event, newValue) => {
        setMinCarat(newValue[0]);
        setMaxCarat(newValue[1]);
        setCaratRangeValue(newValue);
        // formik.setFieldValue("minCarat", newValue[0]);
        // formik.setFieldValue("maxCarat", newValue[1]);
    };

    const handleCaratRangeChangeCommitted = (event, newValue) => {
        formik.setFieldValue("minCarat", newValue[0]);
        formik.setFieldValue("maxCarat", newValue[1]);
    };

    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_CARAT}</div>
                <div className="col-12">
                    <div className="row m-0">
                        <div className="col-md-12">
                            <Slider
                                getAriaLabel={() => "Carat Range"}
                                value={caratRangeValue}
                                onChangeCommitted={handleCaratRangeChangeCommitted}
                                onChange={handleCaratRangeChange}
                                step={0.01}
                                valueLabelDisplay="auto"
                                // getAriaValueText={caratRangeValue}
                                min={minCaratRange}
                                max={maxCaratRange}
                                color="secondary"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between">
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                name="minCarat"
                                variant="outlined"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                value={caratRangeValue[0]}
                                color="secondary"
                                inputProps={{
                                    step: "any",
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                 onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (!isNaN(newValue)) { // Validate if the entered value is a valid number
                                        setMinCarat(newValue);
                                        setCaratRangeValue([
                                            newValue,
                                            caratRangeValue[1],
                                        ]);
                                        formik.setFieldValue("minCarat", newValue);
                                    }
                                }}
                            />
                                
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                variant="outlined"
                                name="maxCarat"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                value={ caratRangeValue[1]}
                                color="secondary"
                                inputProps={{
                                    step: "any", // Allow any step value
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                 onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (!isNaN(newValue)) { // Validate if the entered value is a valid number
                                        setMaxCarat(newValue);
                                        setCaratRangeValue([
                                            caratRangeValue[0],
                                            newValue,
                                        ]);
                                        formik.setFieldValue("maxCarat", newValue);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
