import React from "react";
// white color
import { Depth } from "./Advanced/Depth";
import { Polish } from "./Advanced/Polish";
import { Ratio } from "./Advanced/Ratio";
import { Fluorescence } from "./Advanced/Fluorescence";
import {Table} from "./Advanced/Table";
import { Symmetry } from "./Advanced/Symmetry";
import { Lab } from "./Advanced/Lab";
// white fancy color
import { DepthFancy } from "./AdvancedFancy/Depth";
import { PolishFancy } from "./AdvancedFancy/Polish";
import { RatioFancy } from "./AdvancedFancy/Ratio";
import { FluorescenceFancy } from "./AdvancedFancy/Fluorescence";
import {TableFancy} from "./AdvancedFancy/Table";
import { SymmetryFancy } from "./AdvancedFancy/Symmetry";
import { LabFancy } from "./AdvancedFancy/Lab";
// labgrown
import { DepthLab } from "./AdvancedLab/Depth";
import { PolishLab } from "./AdvancedLab/Polish";
import { RatioLab } from "./AdvancedLab/Ratio";
import { FluorescenceLab } from "./AdvancedLab/Fluorescence";
import {TableLab} from "./AdvancedLab/Table";
import { SymmetryLab } from "./AdvancedLab/Symmetry";
import { LabLab } from "./AdvancedLab/Lab";
// labgrown fancy color
import { DepthLabFancy } from "./AdvancedLabFancy/Depth";
import { PolishLabFancy } from "./AdvancedLabFancy/Polish";
import { RatioLabFancy } from "./AdvancedLabFancy/Ratio";
import { FluorescenceLabFancy } from "./AdvancedLabFancy/Fluorescence";
import {TableLabFancy} from "./AdvancedLabFancy/Table";
import { SymmetryLabFancy } from "./AdvancedLabFancy/Symmetry";
import { LabLabFancy } from "./AdvancedLabFancy/Lab";
// import axios from "axios";
// import { NODE_API_URL } from "../../../../../env_config";
export const Advanced = ({formik,setSearchData,diamond_type,custompage,setLoader,naturalData,naturalFancyData,labData,labFancyData,fancy,fancyLab,language,location}) => {
   
       return (
        <>
           <div className="row m-0">
                <div className="col-md-4">
                    {diamond_type === "N" ?
                        !fancy ?
                            naturalData[0] && naturalData[0].polish ?  <Polish formik={formik} naturalData={naturalData} language={language}/>: null
                            : naturalFancyData[0] && naturalFancyData[0].polish ?  <PolishFancy formik={formik} naturalFancyData={naturalFancyData} language={language} />:null
                        : 
                        !fancyLab ? 
                            labData[0] && labData[0].lab_polish ?  <PolishLab formik={formik} labData={labData} language={language} />: null
                            : labFancyData[0] && labFancyData[0].lab_polish ? <PolishLabFancy formik={formik} labFancyData={labFancyData} language={language} /> : null
                    }
                </div>
                <div className="col-md-4">
                    { diamond_type === "N" ?
                        !fancy ?
                            naturalData[0] && naturalData[0].symmetry ? <Symmetry formik={formik} naturalData={naturalData} language={language} /> : null
                            : naturalFancyData[0] && naturalFancyData[0].symmetry ? <SymmetryFancy formik={formik} naturalFancyData={naturalFancyData} language={language} />:null
                        : 
                        !fancyLab ?
                            labData[0] && labData[0].lab_symmetry ? <SymmetryLab formik={formik} labData={labData} language={language} />:null
                            : labFancyData[0] && labFancyData[0].lab_symmetry ? <SymmetryLabFancy formik={formik} labFancyData={labFancyData} language={language} />:null
                    }
                </div>
                <div className="col-md-4">
                    { diamond_type === 'N' ?   
                        !fancy ?
                            naturalData[0] && naturalData[0].fluorescence ? <Fluorescence formik={formik} naturalData={naturalData} language={language} />:null
                            : naturalFancyData[0] && naturalFancyData[0].fluorescence ? <FluorescenceFancy formik={formik} naturalFancyData={naturalFancyData} language={language} />:null
                        : 
                        !fancyLab ?
                            labData[0] && labData[0].lab_fluorescence ? <FluorescenceLab formik={formik} labData={labData} language={language} />:null
                            : labFancyData[0] && labFancyData[0].lab_fluorescence ?  <FluorescenceLabFancy formik={formik} labFancyData={labFancyData} language={language} />:null
                    }
                </div>
                <div className="col-md-4">
                    {diamond_type === "N"?
                        !fancy ?
                            <Depth formik={formik} naturalData={naturalData} language={language} location={location}/>
                            : <DepthFancy formik={formik} naturalFancyData={naturalFancyData} language={language} location={location}/>
                        : 
                        !fancyLab ?
                            <DepthLab formik={formik} labData={labData} language={language} location={location}/>
                            : <DepthLabFancy formik={formik} labFancyData={labFancyData} language={language} location={location}/>
                    }
                </div>
                <div className="col-md-4">
                    {diamond_type === "N"?
                        !fancy ?
                            <Table formik={formik} naturalData={naturalData} language={language} location={location}/>
                            : <TableFancy formik={formik} naturalFancyData={naturalFancyData} language={language} location={location}/>
                        : 
                        !fancyLab ?
                            <TableLab formik={formik} labData={labData} language={language} location={location}/>
                            : <TableLabFancy formik={formik} labFancyData={labFancyData} language={language} location={location}/>
                    }
                </div>
                <div className="col-md-4">
                    {diamond_type === "N" ? 
                        !fancy ?
                            <Ratio formik={formik} naturalData={naturalData}  language={language} location={location}/>
                            : <RatioFancy formik={formik} naturalFancyData={naturalFancyData} language={language} location={location}/>
                        : 
                        !fancyLab ? 
                            <RatioLab formik={formik} labData={labData} language={language} location={location}/>
                            : <RatioLabFancy formik={formik} labFancyData={labFancyData} language={language} location={location}/>
                    }
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    {diamond_type === "N" ?
                        !fancy ?
                            naturalData[0] && naturalData[0].lab ? <Lab formik={formik} naturalData={naturalData} language={language} />:null
                            : naturalFancyData[0] && naturalFancyData[0].lab ?  <LabFancy formik={formik} naturalFancyData={naturalFancyData} language={language} />:null
                        : 
                        !fancyLab ?
                            labData[0] && labData[0].lab_lab ? <LabLab formik={formik} labData={labData} language={language} />:null
                            : labFancyData[0] && labFancyData[0].lab_lab ?  <LabLabFancy formik={formik} labFancyData={labFancyData} language={language} />: null
                    }
                </div>
                <div className="col-md-4"></div>
            </div>
        </>
    );
};
