import React ,{useState}from "react";
import { Field } from "formik";



export const Cut =({formik,naturalData,language}) =>{

    const [cutData,setWhiteColor] = useState(naturalData.length !== 0 && naturalData[0].cut !== null ? naturalData[0].cut : [])

    // const CutData = ([
    //     {
    //         id: 1,
    //         cut:"ID"
    //     },
    //     {
    //         id: 2,
    //         cut:"EX"
    //     },
    //     {
    //         id: 3,
    //         cut:"VG"
    //     },
    //     {
    //         id: 4,
    //         cut:"GD"
    //     },
    //     {
    //         id: 5,
    //         cut:"FR"
    //     },
    //     {
    //         id: 6,
    //         cut:"PR"
    //     }
    // ]);

    const newCutData =cutData.length!==0 ? cutData.split(',').map((value,index) => {
        return {
            id: index,
            cut:value
        }
    }):null
    return (
        <>
            <div className="row mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_FINISH_CUT}</div>
                <div className="col-12" >
                    {newCutData ?newCutData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Cut" id={`${item.cut}_cut`} value={item.cut}/>
                            <div className="wColorBox selectBox">
                                <span>{item.cut}</span>
                            </div>
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
}