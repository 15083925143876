import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../_helpers";

function ClarityBlock({ clarity }) {
    useEffect(() => {
        const getLeftPercent = (claritie) => {
            switch (claritie) {
                case "FL":
                case "IF":
                    return 7.5;
                case "VVS1":
                case "VVS2":
                    return 28.5;
                case "VS1":
                case "VS2":
                    return 50.2;
                case "SI1":
                case "SI2":
                    return 71.5;
                case "I1":
                case "I2":
                case "I3":
                    return 93.5;
                default:
                    return 0;
            }
        };

        const clarityBlockElements = document.querySelectorAll(
            '[data-toggle="claritie-block"]'
        );
        clarityBlockElements.forEach((element) => {
            const claritieValue = element.getAttribute("data-claritie");
            const leftPercent = getLeftPercent(claritieValue);
            const width = (window.innerWidth - 30) * (leftPercent / 100);
            if (width > 70) {
                element.style.left = leftPercent + "%";
                element.querySelector(".arrow").style.display = "block";
            }
        });
    }, []);

    return (
        <div className="stone-detail-carat-box">
            <p>
                <b>Clarity: {clarity}</b>
            </p>
            <div
                className="point mb-6"
                data-toggle="claritie-block"
                data-claritie={clarity}
            >
                <span className="arrow" ></span>
            </div>
            <img src={toAbsoluteUrl("/media/General/detail/clarity-range.png")} className="w-100 mt-4" alt="clarity Range"/>
           
        </div>
    );
}

export default ClarityBlock;
