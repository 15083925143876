import React,{useState} from "react";
import { Field } from "formik";

export const FinishLabFancy = ({formik,labFancyData,language}) => {
    const [cutData,setWhiteColor] = useState(labFancyData.length !== 0  && labFancyData[0].lab_cut !== null ? labFancyData[0].lab_cut : [])
    const [polishData,setPolishData] =useState(labFancyData.length !== 0  && labFancyData[0].lab_polish !== null ? labFancyData[0].lab_polish : [])
    const [symmData,setSymmData] =useState(labFancyData.length !== 0  && labFancyData[0].lab_symmetry !== null ? labFancyData[0].lab_symmetry : [])

    const newCutData =cutData.length!==0 ? cutData.split(',').map((value,index) => {
        return {
            id: index,
            cut:value
        }
    }):null

    const newPolishData =polishData.length!==0 ? polishData.split(',').map((value,index) => {
        return {
            id: index,
            polish:value,
        }
    }):null

    const newSymmetryData =symmData.length!==0 ? symmData.split(',').map((value,index) => {
        return {
            id: index,
            symmetry:value,
        }
    }):null

    // const CutData = ([
    //     {
    //         id: 1,
    //         cut:"ID"
    //     },
    //     {
    //         id: 2,
    //         cut:"EX"
    //     },
    //     {
    //         id: 3,
    //         cut:"VG"
    //     },
    //     {
    //         id: 4,
    //         cut:"GD"
    //     },
    //     {
    //         id: 5,
    //         cut:"FR"
    //     },
    //     {
    //         id: 6,
    //         cut:"PR"
    //     }
    // ]);
    // const PolishData = ([
    //     {
    //         id: 1,
    //         polish:"ID"
    //     },
    //     {
    //         id: 2,
    //         polish:"EX"
    //     },
    //     {
    //         id: 3,
    //         polish:"VG"
    //     },
    //     {
    //         id: 4,
    //         polish:"GD"
    //     },
    //     {
    //         id: 5,
    //         polish:"FR"
    //     },
    //     {
    //         id: 6,
    //         polish:"PR"
    //     }
    // ]);
    // const SymmetryData = ([
    //     {
    //         id: 1,
    //         symmetry:"ID"
    //     },
    //     {
    //         id: 2,
    //         symmetry:"EX"
    //     },
    //     {
    //         id: 3,
    //         symmetry:"VG"
    //     },
    //     {
    //         id: 4,
    //         symmetry:"GD"
    //     },
    //     {
    //         id: 5,
    //         symmetry:"FR"
    //     },
    //     {
    //         id: 6,
    //         symmetry:"PR"
    //     }
    // ]);

    return (
        <>
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6">{language.CCMODE_THEME1_SEARCHDIAMOND_FINISH}</p>
                </div>
                <div className="col-lg-10 d-flex">
                    {newCutData ? 
                        <div className="mr-8">
                            <label htmlFor="">{language.CCMODE_THEME1_SEARCHDIAMOND_FINISH_CUT}</label><br />
                            {newCutData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Cut" id={`${item.cut}_cut`} value={item.cut}/>
                                    <div className="finishBox selectBox">
                                        <span>{item.cut}</span>
                                    </div>
                                </label>
                            ))}
                        </div> 
                    : null }
                    {newPolishData ? 
                        <div className="mr-8">
                            <label htmlFor="">{language.CCMODE_THEME1_SEARCHDIAMOND_FINISH_POLISH}</label><br />
                            {newPolishData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Polish" id={`${item.polish}_polish`} value={item.polish}/>
                                    <div className="finishBox selectBox">
                                        <span>{item.polish}</span>
                                    </div>
                                </label>
                            ))}
                        </div> 
                    : null}
                    {newSymmetryData ? 
                        <div>
                            <label htmlFor="">{language.CCMODE_THEME1_SEARCHDIAMOND_FINISH_SYMMETRY}</label><br />
                            {newSymmetryData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Symmetry" id={`${item.symmetry}_symmetry`} value={item.symmetry}/>
                                    <div className="finishBox selectBox">
                                        <span>{item.symmetry}</span>
                                    </div>
                                </label>
                            ))}
                        </div> 
                    : null }
                </div>
            </div>
        </>
    );
};