import React,{useState} from "react";
import { Field } from "formik";

export const ColorLab =({formik,labData,setFancyLab,fancyLab,labFancyData,language}) =>{
    const [whiteColor] =useState(labData.length !== 0 && labData[0].lab_color !== null ? labData[0].lab_color : [])
    const newWhiteColorData =whiteColor.length!==0 ? whiteColor.split(',').map((value,index) => {
        return {
            id: index,
            wcolor:value
        }
    }):null
    const ResetColor = () => {
       
    }
    return (
        <>
            <div className="row mt-7" onClick={() => ResetColor()}>
            <div className="col-12 font-size-lg font-weight-bold mb-3">
                    {language.CCMODE_THEME1_SEARCHDIAMOND_COLOR}
                    <span className="small font-weight-bold">
                            {labData && labData.length && labData[0].status === 1?<span className={`${!fancyLab ? "text-primary" : ""} cursor-pointer ml-2`} onClick={()=>setFancyLab(false)}>{language.CCMODE_THEME1_SEARCHDIAMOND_COLOR_WHITE}</span>:""}
                            {labData && labData.length && labData[0].status === 1 && labFancyData && labFancyData.length && labFancyData[0].status === 1?<>/</>:""}
                            {labFancyData && labFancyData.length && labFancyData[0].status === 1?<span className={`${fancyLab ? "text-primary" : ""} cursor-pointer`} onClick={()=>setFancyLab(true)}>{language.CCMODE_THEME1_SEARCHDIAMOND_COLOR_FANCY}</span>:""}
                        </span>

                </div>
                <div className="col-12" >
                    {newWhiteColorData ? newWhiteColorData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Color" id={`${item.wcolor}_wcolor`} value={item.wcolor}/>
                            <div className="wColorBox selectBox">
                                <span>{item.wcolor}</span>
                            </div>
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
}

// export const Color = ({formik}) => {
//     const classes = useStyles();
//     const [minColor, setMinColor] = useState(0);
//     const [maxColor, setMaxColor] = useState(100);
//     const [colorRangeValue, setColorRangeValue] = useState([0, 100]);
//     const minColorRange = 0;
//     const maxColorRange = 100;

//     useEffect(() => {
//         formik.setFieldValue("minColor", minColor);
//         formik.setFieldValue("maxColor", maxColor);
//     }, []);

//     const marks = [
//         {
//           value: 0,
//           label: 'D',
//         },
//         {
//           value: 10,
//           label: 'E',
//         },
//         {
//           value: 20,
//           label: 'F',
//         },
//         {
//           value: 30,
//           label: 'G',
//         },
//         {
//             value: 40,
//             label: 'H',
//         },
//         {
//             value: 50,
//             label: 'I',
//         },
//         {
//             value: 60,
//             label: 'J',
//         },
//         {
//             value: 70,
//             label: 'K',
//         },
//         {
//             value: 80,
//             label: 'L',
//         },
//         {
//             value: 90,
//             label: 'M',
//         },
//         {
//             value: 100,
//             label: 'N',
//         },
//       ];

//     const handleColorRangeChange = (event, newValue) => {
//         setMinColor(newValue[0]);
//         setMaxColor(newValue[1]);
//         setColorRangeValue(newValue);
//         formik.setFieldValue("minColor", newValue[0]);
//         formik.setFieldValue("maxColor", newValue[1]);
//     };

//     return (
//         <>
//             <div className="row m-0">
//                 <div className="col-12 font-size-lg font-weight-bold mb-3">Color</div>
//                 <div className="col-12">
//                     <div className="row m-0">
//                         <div className="col-md-12">
//                             <Slider
//                                 getAriaLabel={() => "Color Range"}
//                                 value={colorRangeValue}
//                                 onChange={handleColorRangeChange}
//                                 step={10}
//                                 // valueLabelDisplay="auto"
//                                 marks={marks}
//                                 // valueLabelDisplay="on"
//                                 // getAriaValueText={colorRangeValue}
//                                 min={minColorRange}
//                                 max={maxColorRange}
//                                 color="secondary"
//                             />
//                         </div>
//                     </div>
//                     {/* <div className="row">
//                         <div className="col-md-12 d-flex justify-content-between">
//                             <TextField
//                                 className={classes.TextField}
//                                 margin="dense"
//                                 name="minColor"
//                                 variant="outlined"
//                                 InputLabelProps={{ shrink: true }}
//                                 value={colorRangeValue[0]}
//                                 color="secondary"
//                                 inputProps={{
//                                     style: {
//                                       padding: 5,
//                                       textAlign:"center"
//                                     }
//                                  }}
//                                 onChange={(e) => {
//                                     setMinColor(Number(e.target.value));
//                                     setColorRangeValue([
//                                         Number(e.target.value),
//                                         colorRangeValue[1],
//                                     ]);
//                                     formik.setFieldValue("minColor", Number(e.target.value));
//                                 }}
//                             />
                                
//                             <TextField
//                                 className={classes.TextField}
//                                 margin="dense"
//                                 variant="outlined"
//                                 name="maxColor"
//                                 InputLabelProps={{ shrink: true }}
//                                 value={ colorRangeValue[1]}
//                                 color="secondary"
//                                 inputProps={{
//                                     style: {
//                                       padding: 5,
//                                       textAlign:"center"
//                                     }
//                                  }}
//                                 onChange={(e) => {
//                                     setMaxColor(Number(e.target.value));
//                                     setColorRangeValue([
//                                         colorRangeValue[0],
//                                         Number(e.target.value),
//                                     ]);
//                                     formik.setFieldValue("maxColor", Number(e.target.value));
//                                 }}
//                             />
//                         </div>
//                     </div> */}
//                 </div>
//             </div>
//         </>
//     );
// };
