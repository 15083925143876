import React, { useMemo } from "react";
import { GridCard } from "./Grid/GridCard";
import {  FaAngleLeft, FaAngleRight } from "react-icons/fa";
// import Loader from "../searchDiamond/Loader";
import GridSkeleton from "../../GridSkeleton";
export const GridResult = (props) => {
    // const [data, setData] = React.useState(StockAPI);
    const data = useMemo(()=> props.searchdata.data || [],[props.searchdata.data])
    return (
        <>
        {props.loader && props.custompage === 1?<GridSkeleton />:""}
        <div>
            
        {data.length?<div className="row mt-5">
            {data.map((val) => {
                return (
                    <GridCard
                        key={val.id}
                        {...val}
                        setShortlist={props.setShortlist}
                        shortlist={props.shortlist}
                        language={props.language}
                        themeData={props.themeData}
                        ipData={props.ipData}
                    />
                );
            })}
        </div>:<div className="text-center mt-5">No Diamonds Found</div>}
            
        </div>
        {data.length % 100?<div className="text-center mt-5">No More Records</div>:""}
        {props.loader  && props.custompage !== 1?<GridSkeleton />:""}

        </>
    );
};
