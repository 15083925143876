import React,{useState} from "react";
import { Field } from "formik";


export const LabFancy =({formik,naturalFancyData,language}) =>{ 
    const [labData,setLabData] =useState(naturalFancyData.length !== 0 && naturalFancyData[0].lab !== null ? naturalFancyData[0].lab : [])

    // const LabData = ([
    //     {
    //         id: 1,
    //         lab:"GIA"
    //     },
    //     {
    //         id: 2,
    //         lab:"IGI"
    //     },
    //     {
    //         id: 3,
    //         lab:"HRD"
    //     },
    //     {
    //         id: 4,
    //         lab:"OTHER"
    //     }
    // ]);

    const newLabData =labData.length!==0 ? labData.split(',').map((value,index) => {
        return {
            id: index,
            lab:value,
        }
    }):null

    return (
        <>
            <div className="row m-0 mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_LIST_LAB}</div>
                <div className="col-12" >
                    {newLabData ? newLabData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Lab" id={`${item.lab}_lab`} value={item.lab}/>
                            <div className="wLabBox selectBox">
                                <span>{item.lab}</span>
                            </div>
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
}