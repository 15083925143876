import React,{useState} from "react";
import { Field } from "formik";



export const SymmetryLab =({formik,labData,language}) =>{
    const [symmData,setSymmData] =useState(labData.length !== 0 ? labData[0].lab_symmetry : [])

    // const SymmetryData = ([
    //     {
    //         id: 1,
    //         symmetry:"ID"
    //     },
    //     {
    //         id: 2,
    //         symmetry:"EX"
    //     },
    //     {
    //         id: 3,
    //         symmetry:"VG"
    //     },
    //     {
    //         id: 4,
    //         symmetry:"GD"
    //     },
    //     {
    //         id: 5,
    //         symmetry:"FR"
    //     },
    //     {
    //         id: 6,
    //         symmetry:"PR"
    //     }
    // ]);

    const newSymmetryData =symmData.length!==0 ? symmData.split(',').map((value,index) => {
        return {
            id: index,
            symmetry:value,
        }
    }):null

    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_FINISH_SYMMETRY}</div>
                <div className="col-12" >
                    {newSymmetryData ? newSymmetryData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Symmetry" id={`${item.symmetry}_symmetry`} value={item.symmetry}/>
                            <div className="wColorBox selectBox">
                                <span>{item.symmetry}</span>
                            </div>
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
}