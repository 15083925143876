import React,{useState } from "react";
import { Field } from "formik";
import {FaAngleDown,FaAngleUp} from "react-icons/fa"
// import { Slider,makeStyles } from "@material-ui/core";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";


export const FancyLabColor =({formik,setFancyLab,fancyLab,labFancyData,labData,language}) =>{
    const [colorData,setColorData] =useState(labFancyData.length !== 0 && labFancyData[0].lab_fancy_color !== null ? labFancyData[0].lab_fancy_color : [])
    const [intensityData,setIntensityData] =useState(labFancyData.length !== 0 && labFancyData[0].lab_fancy_intensity !== null ? labFancyData[0].lab_fancy_intensity : [])
    const [overtoneData,setOvertoneData] =useState(labFancyData.length !== 0 && labFancyData[0].lab_fancy_overtone !== null ? labFancyData[0].lab_fancy_overtone : [])
    const [colorLimit, setColorLimit] = useState(colorData.length < 5 ? colorData.length : 5);
    const [intensityLimit, setIntensityLimit] = useState(intensityData.length < 5 ? intensityData.length : 5);
    const [overToneLimit, setOverToneLimit] = useState(overtoneData.length < 5 ? overtoneData.length : 5);


    // color data
    // const ColorData = ([
    //     {
    //         id: 1,
    //         value:"YELLOW",
    //         color:"YELLOW"
    //     },
    //     {
    //         id: 2,
    //         value:"PINK",
    //         color:"PINK"
    //     },
    //     {
    //         id: 3,
    //         value:"BLUE",
    //         color:"BLUE"
    //     },
    //     {
    //         id: 4,
    //         value:"RED",
    //         color:"RED"
    //     },
    //     {
    //         id: 5,
    //         value:"GREEN",
    //         color:"GREEN"
    //     },
    //     {
    //         id: 6,
    //         value:"PURPLE",
    //         color:"PURPLE"
    //     },
    //     {
    //         id: 7,
    //         value:"ORANGE",
    //         color:"ORANGE"
    //     },
    //     {
    //         id: 8,
    //         value:"VIOLET",
    //         color:"VIOLET"
    //     },
    //     {
    //         id: 10,
    //         value:"GREY",
    //         color:"GREY"
    //     },
    //     {
    //         id: 11,
    //         value:"BLACK",
    //         color:"BLACK"
    //     },
    //     {
    //         id: 12,
    //         value:"BROWN",
    //         color:"BROWN"
    //     },
    //     {
    //         id: 13,
    //         value:"Champagne",
    //         color:"Champagne"
    //     },
    //     {
    //         id: 14,
    //         value:"Cognac",
    //         color:"Cognac"
    //     },
    //     {
    //         id: 15,
    //         value:"Chameleon",
    //         color:"Chameleon"
    //     },
    //     {
    //         id: 16,
    //         value:"WHITE",
    //         color:"WHITE"
    //     },
    //     {
    //         id: 17,
    //         value:"Other",
    //         color:"Other"
    //     }
    // ]);

    const newColorDataData =colorData.length!==0 ? colorData.split(',').map((value,index) => {
        return {
            id: index,
            value:value,
            color:value
        }
    }):null

    const showMoreColor = () => {
        setColorLimit(newColorDataData.length);
    };

    const showLessColor = () => {
        setColorLimit(5);
    };

    // intensity data
    // const IntensityData = ([
    //     {
    //         id: "int_1",
    //         value:"FAINT",
    //         intensity:"FAINT"
    //     },
    //     {
    //         id: "int_2",
    //         value:"VERY LIGHT",
    //         intensity:"VERY LIGHT"
    //     },
    //     {
    //         id: "int_3",
    //         value:"LIGHT",
    //         intensity:"LIGHT"
    //     },
    //     {
    //         id: "int_4",
    //         value:"FANCY LIGHT",
    //         intensity:"FANCY LIGHT"
    //     },
    //     {
    //         id: "int_5",
    //         value:"FANCY",
    //         intensity:"FANCY"
    //     },
    //     {
    //         id: "int_6",
    //         value:"FANCY DARK",
    //         intensity:"FANCY DARK"
    //     },
    //     {
    //         id: "int_7",
    //         value:"FANCY INTENSE",
    //         intensity:"FANCY INTENSE"
    //     },
    //     {
    //         id: "int_8",
    //         value:"FANCY VIVID",
    //         intensity:"FANCY VIVID"
    //     },
    //     {
    //         id: "int_9",
    //         value:"FANCY DEEP",
    //         intensity:"FANCY DEEP"
    //     }
    // ]);

    const newIntensityData =intensityData.length!==0 ? intensityData.split(',').map((value,index) => {
        return {
            id: `int_${index}`,
            value:value,
            intensity:value
        }
    }):null

    const showMoreIntensity = () => {
        setIntensityLimit(newIntensityData.length);
    };

    const showLessIntensity = () => {
        setIntensityLimit(5);
    };

    // overtone data
    // const OvertoneData = ([
    //     {
    //         id: 1,
    //         value:"NONE",
    //         overtone:"NONE"
    //     },
    //     {
    //         id: 0,
    //         value:"YELLOW",
    //         overtone:"YELLOW"
    //     },
    //     {
    //         id: 2,
    //         value:"Yellowish",
    //         overtone:"Yellowish"
    //     },
    //     {
    //         id: 3,
    //         value:"Pink",
    //         overtone:"Pink"
    //     },
    //     {
    //         id: 4,
    //         value:"Pinkish",
    //         overtone:"Pinkish"
    //     },
    //     {
    //         id: 5,
    //         value:"Blue",
    //         overtone:"Blue"
    //     },
    //     {
    //         id: 6,
    //         value:"Blueish",
    //         overtone:"Blueish"
    //     },
    //     {
    //         id: 7,
    //         value:"Red",
    //         overtone:"Red"
    //     },
    //     {
    //         id: 8,
    //         value:"Reddish",
    //         overtone:"Reddish"
    //     },
    //     {
    //         id: 9,
    //         value:"Green",
    //         overtone:"Green"
    //     },
    //     {
    //         id: 10,
    //         value:"Greenish",
    //         overtone:"Greenish"
    //     },
    //     {
    //         id: 11,
    //         value:"Purple",
    //         overtone:"Purple"
    //     },
    //     {
    //         id: 12,
    //         value:"Purplish",
    //         overtone:"Purplish"
    //     },
    //     {
    //         id: 13,
    //         value:"Orange",
    //         overtone:"Orange"
    //     },
    //     {
    //         id: 14,
    //         value:"Orangy",
    //         overtone:"Orangy"
    //     },
    //     {
    //         id: 15,
    //         value:"VIOLET",
    //         overtone:"VIOLET"
    //     },
    //     {
    //         id: 16,
    //         value:"Violetish",
    //         overtone:"Violetish"
    //     },
    //     {
    //         id: 17,
    //         value:"Gray",
    //         overtone:"Gray"
    //     },
    //     {
    //         id: 18,
    //         value:"Grayish",
    //         overtone:"Grayish"
    //     },
    //     {
    //         id: 19,
    //         value:"Black",
    //         overtone:"Black"
    //     },
    //     {
    //         id: 20,
    //         value:"Brown",
    //         overtone:"Brown"
    //     },
    //     {
    //         id: 21,
    //         value:"BROWNISH",
    //         overtone:"BROWNISH"
    //     },
    //     {
    //         id: 22,
    //         value:"Champagne",
    //         overtone:"Champagne"
    //     },
    //     {
    //         id: 23,
    //         value:"Cognac",
    //         overtone:"Cognac"
    //     },
    //     {
    //         id: 24,
    //         value:"Chameleon",
    //         overtone:"Chameleon"
    //     },
    //     {
    //         id: 25,
    //         value:"WHITE",
    //         overtone:"WHITE"
    //     }
    //     ,
    //     {
    //         id: 26,
    //         value:"Other",
    //         overtone:"Other"
    //     }
    // ]);
    
    const newOverToneData =overtoneData.length!==0 ? overtoneData.split(',').map((value,index) => {
        return {
            id: `ovr_${index}`,
            value:value,
            overtone:value
        }
    }):null

    
    const showMoreOverTone = () => {
        setOverToneLimit(newOverToneData.length);
        // setOverToneLimit(overToneLimit + 3);
    };

    const showLessOverTone = () => {
        setOverToneLimit(5);
    };

   
    const ResetColor = () => {
        // formik.setFieldValue("Color",[])
    }
    return (
        <div className="row " onClick={() => ResetColor()}>
            <div className="col-md-4">
                <div className="row mt-7">
                    <div className="col-12 font-size-lg font-weight-bold mb-3">
                        {language.CCMODE_THEME3_SEARCHDIAMOND_COLOR}
                        <span className="small font-weight-bold">
                            {labData && labData.length && labData[0].status === 1?<span className={`${!fancyLab ? "text-primary" : ""} cursor-pointer ml-2`} onClick={()=>setFancyLab(false)}>{language.CCMODE_THEME1_SEARCHDIAMOND_COLOR_WHITE}</span>:""}
                            {labData && labData.length && labData[0].status === 1 && labFancyData && labFancyData.length && labFancyData[0].status === 1?<>/</>:""}
                            {labFancyData && labFancyData.length && labFancyData[0].status === 1?<span className={`${fancyLab ? "text-primary" : ""} cursor-pointer`} onClick={()=>setFancyLab(true)}>{language.CCMODE_THEME1_SEARCHDIAMOND_COLOR_FANCY}</span>:""}
                        </span>

                    </div>
                    {newColorDataData?<div className="col-12" >
                        {newColorDataData.slice(0, colorLimit).map((item) => (
                            <label key={item.id} className="search_btn_check">
                                <Field type="checkbox" name="Fancy_Color" id={`${item.id}`} value={item.value}/>
                                <div className="fancyBox selectBox">
                                    <span>{item.color}</span>
                                </div>
                            </label>
                        ))}
                        {newColorDataData.length > 5 ?
                        <span className="text-primary font-weight-bold ml-5 cursor-pointer text-hover-warning" onClick={newColorDataData.length === newColorDataData.slice(0, colorLimit).length ? showLessColor : showMoreColor}>{newColorDataData.length === newColorDataData.slice(0, colorLimit).length ? <>Less..<FaAngleUp className="pl-1"/></> : <>More..<FaAngleDown className="pl-1"/></>}</span> : null}
                    </div>:""}
                </div>
            </div>
            {newIntensityData?<div className="col-md-4">
                <div className="row mt-7">
                    <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME3_SEARCHDIAMOND_FANCY_INTENSITY}</div>
                    <div className="col-12" >
                        {newIntensityData.slice(0, intensityLimit).map((item) => (
                            <label key={item.id} className="search_btn_check">
                                <Field type="checkbox" name="Fancy_Color_Intensity" id={`${item.id}`} value={item.value}/>
                                <div className="fancyBox selectBox">
                                    <span>{item.intensity}</span>
                                </div>
                            </label>
                        ))}
                        {/* <label className="search_btn_check" onClick={showMoreIntensity}>
                            <div className="fancyBox selectBox">
                                <span>Load More...</span>
                            </div>
                        </label> */}
                        {newIntensityData.length > 5 ?
                        <span className="text-primary font-weight-bold ml-5 cursor-pointer text-hover-warning" onClick={newIntensityData.length === newIntensityData.slice(0, intensityLimit).length ? showLessIntensity : showMoreIntensity}>{newIntensityData.length === newIntensityData.slice(0, intensityLimit).length ? <>Less..<FaAngleUp className="pl-1"/></> : <>More..<FaAngleDown className="pl-1"/></>}</span> : null}
                    </div>
                </div>
            </div>:""}
            {newOverToneData?<div className="col-md-4">
                <div className="row mt-7">
                    <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME3_SEARCHDIAMOND_FANCY_OVERTONE}</div>
                    <div className="col-12" >
                        {newOverToneData.slice(0, overToneLimit).map((item) => (
                            <label key={item.id} className="search_btn_check">
                                <Field type="checkbox" name="Fancy_Color_Overtone" id={`${item.id}`} value={item.value}/>
                                <div className="fancyBox selectBox">
                                    <span>{item.overtone}</span>
                                </div>
                            </label>
                        ))}
                        {newOverToneData.length > 5 ?
                        <span className="text-primary font-weight-bold ml-5 cursor-pointer text-hover-warning" onClick={newOverToneData.length === newOverToneData.slice(0, overToneLimit).length ? showLessOverTone : showMoreOverTone}>{newOverToneData.length === newOverToneData.slice(0, overToneLimit).length ? <>Less..<FaAngleUp className="pl-1"/></> : <>More..<FaAngleDown className="pl-1"/></>}</span> : null}
                    </div>
                </div>
            </div>:""}
        </div>
    );
}