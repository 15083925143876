/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ColorBlock from "./ColorBlock";
import CaratBlock from "./CaratBlock";
import ClarityBlock from "./ClarityBlock";
import CutBlock from "./CutBlock";
import FancyColorBlock from "./FancyColorBlock";

export const DetailBlock = (props) => {
    return (
        <div className="row mb-10">
            <div className="col-md-6 mt-10">
                <CaratBlock caratWeight={props.carat} />
            </div>
            <div className="col-md-6 mt-10">
                {props.color === undefined ? (
                    <FancyColorBlock color={props.color} intensity={props.intensity} fancycolor={props.fancycolor}/>
                ) : (
                    <ColorBlock color={props.color} />
                )}
            </div>
            <div className="col-md-6 mt-10">
                <ClarityBlock clarity={props.clarity} />
            </div>
            {props.color !== undefined && props.shape.toUpperCase() === "ROUND" ? (
                <div className="col-md-6 mt-10">
                    <CutBlock cut={props.cut} />
                </div>
            ) : null}
        </div>
    );
};
