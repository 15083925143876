import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../_helpers";
function ColorBlock({ color }) {
    useEffect(() => {
        const getColorLeftPercent = (color) => {
            switch (color) {
                case "D":
                    return 6;
                case "E":
                    return 16;
                case "F":
                    return 27;
                case "G":
                    return 38.2;
                case "H":
                    return 49.7;
                case "I":
                    return 61;
                case "J":
                    return 73;
                case "K":
                case "L":
                case "M":
                    return 84.5;
                default:
                    return 95.5;
            }
        };

        const colorBlockElements = document.querySelectorAll(
            '[data-toggle="color-block"]'
        );
        colorBlockElements.forEach((element) => {
            const colorValue = element.getAttribute("data-color");
            const leftPercent = getColorLeftPercent(colorValue);
            const width = (window.innerWidth - 30) * (leftPercent / 100);
            if (width > 70) {
                element.style.left = leftPercent + "%";
                element.querySelector(".arrow").style.display = "block";
            }
        });
    }, []);

    return (
        <div className="stone-detail-carat-box white-color">
            <p>
                <b>Color: {color}</b>
            </p>
            <div className="point mb-6" data-toggle="color-block" data-color={color}>
                <span className="arrow" ></span>
            </div>
            <img src={toAbsoluteUrl("/media/General/detail/color-range.png")} className="w-100 mt-4" alt="Color Range"/>
            {/* <img
                src="/assets/plugin/images/color-range.png"
                className="w-100 mt-4"
                alt="Color Range"
            /> */}
        </div>
    );
}

export default ColorBlock;
