import React, {useMemo} from 'react'
import {useTable, useGlobalFilter, usePagination, useRowSelect,useExpanded } from 'react-table';
import { searchResultColumn } from './List/Columns'; 
import {  FaAngleLeft, FaAngleDown } from "react-icons/fa";
import ListSkeleton from '../../ListSkeleton';
import ExpandedDetails from '../../expandedDetails/ExpandedDetails';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ListResult = (props) => {

    const data = useMemo(()=> props.searchdata.data || [],[props.searchdata.data])

    const columns = useMemo(() => searchResultColumn(props.themeData,props.language), []);
    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
             {/* <code>{JSON.stringify({ values: row.row.original }, null, 2)}</code> */}
                <ExpandedDetails row={row.row.original} themeData={props.themeData} ipData={props.ipData} language={props.language}/>
            </>
        ),[data]
      )
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        visibleColumns,
        page,
        // state:{ globalFilter, pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        data,
        initialState: { pageSize: props.stoneCount }
    },
    useGlobalFilter,
    // useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    ...columns,
                    {
                        Header: () => null,
                        id: 'expander',
                        Cell: ({ row }) => (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent row click event propagation
                                    row.toggleRowExpanded();
                                }}
                            >
                                {row.isExpanded ? 
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip">Collapse</Tooltip>}
                                    >
                                        <FaAngleDown className="text-success font-size-h6"  title="Collapse"/> 
                                    </OverlayTrigger>
                                : 
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip">Expand</Tooltip>}
                                    >
                                        <FaAngleLeft className="text-primary font-size-h6" title="Expand"/>
                                    </OverlayTrigger>
                                }
                            </span>
                        ),
                    },
                ]
            })
        }
    )
    return (
        <>
        {/* <ListSkeleton /> */}
        <div className='row mt-5'>
            <div className='col-12'>
                <div className="table-responsive w-100">
                    <table className="table table-striped table-hover custom_datatable" {...getTableProps()}>
                        <thead className="bg-gray-100">
                            {headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                    {headerGroup.headers.map(
                                        (column, i) => (
                                            <th key={i} className="text-center align-middle text-nowrap" id={column.id}>
                                                {column.render("Header")}
                                                {/* <span className="text-secondary">
                                                    {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                </span> */}
                                            </th>
                                        )
                                    )}
                                </tr>
                            ))}
                        </thead>
                        {props.loader && props.custompage === 1?<ListSkeleton/>:""}
                        {page.length?<tbody {...getTableBodyProps()}>
                            {page.map((row,i) => {
                                prepareRow(row)
                                return(
                                    <React.Fragment key={i}>
                                        <tr
                                            {...row.getRowProps()}
                                            key={row.id}
                                            onClick={() => row.toggleRowExpanded()}
                                            style={{ cursor: 'pointer' }} // Change cursor to indicate clickable row
                                        >
                                            {row.cells.map((cell,i) => {
                                                return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                        {row.isExpanded ? (
                                            <tr className='expanded_tr'>
                                                <td className="p-0" colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent({ row })} 
                                                </td>
                                            </tr>
                                        ) : null}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>:<tbody><tr><td className="text-center align-middle" colSpan={"100%"}>No Diamonds Found</td></tr></tbody>}
                        {data.length % 100?<div className="text-center mt-5">No More Records</div>:""}
                        {props.loader && props.custompage !== 1?<ListSkeleton/>:""}
                    </table>
                </div>
            </div>
        </div>

        </>
    )
}