/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
// import schedule from "node-schedule";

export default function App({ store, persistor, basename }) {
  // React.useEffect(() => {
  //   // define increment counter part
  //   const tabsOpen = localStorage.getItem("tabsOpen");
  //   if (tabsOpen == null) {
  //     localStorage.setItem("tabsOpen", 1);
  //   } else {
  //     localStorage.setItem("tabsOpen", parseInt(tabsOpen) + parseInt(1));
  //   }
    
  //   // define decrement counter part
  //   window.onunload = function (e) {
  //     const newTabCount = localStorage.getItem("tabsOpen");
  //     if (newTabCount !== null) {
  //       localStorage.setItem("tabsOpen", newTabCount - 1);
  //     }
  //   };
  //   if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
  //     window.localStorage.isMySessionActive = "false";
  //   } else {
  //     const newTabCount2 = localStorage.getItem("tabsOpen");
  //     let value = localStorage.getItem("isMySessionActive");
  //     if (value == "true") {
  //       if (newTabCount2 - 1 == 0) {
  //         localStorage.clear();
  //         // window.location.reload();
  //         window.location.href = '/';
  //         window.localStorage.isMySessionActive = "false";
  //       } else {
  //         window.localStorage.isMySessionActive = "false";
  //       }
  //     }
  //   }
  //   }, []);
  return (
   <>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
                </I18nProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
   </>
  );
}
