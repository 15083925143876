import React from "react";
import { Accordion, Card } from "react-bootstrap";
// white diam
import { Flour } from "./Advanced/Flour";
import { Lab } from "./Advanced/Lab";
import { Percentage } from "./Advanced/Percentage";
// white fancy diam
import { FlourFancy } from "./AdvancedFancy/Flour";
import { LabFancy } from "./AdvancedFancy/Lab";
import { PercentageFancy } from "./AdvancedFancy/Percentage";
//lab diam 
import { FlourLab } from "./AdvancedLab/Flour";
import { LabLab } from "./AdvancedLab/Lab";
import { PercentageLab } from "./AdvancedLab/Percentage";
//lab fancy diam 
import { FlourLabFancy } from "./AdvancedLabFancy/Flour";
import { LabLabFancy } from "./AdvancedLabFancy/Lab";
import { PercentageLabFancy } from "./AdvancedLabFancy/Percentage";
export const Advanced = ({formik,setSearchData,diamond_type,custompage,setLoader,naturalData,naturalFancyData,labData,labFancyData,fancy,fancyLab,language}) => {
       return (
        <>
            {/* <Accordion className="search_accordion" defaultActiveKey="1"> */}
                <Card className="border-0">
                    {/* <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        Advanced
                    </Accordion.Toggle> */}
                    {/* <Accordion.Collapse eventKey="1"> */}
                        <Card.Body className="p-0">
                            { diamond_type === 'N' ?   
                                !fancy ?
                                    naturalData[0] && naturalData[0].fluorescence ?  <Flour formik={formik} naturalData={naturalData} language={language}/> : null
                                    : naturalData[0] && naturalData[0].fluorescence ? <FlourFancy formik={formik} naturalFancyData={naturalFancyData}  language={language}/> : null
                                : 
                                !fancyLab ?
                                    labData[0] && labData[0].lab_fluorescence ?  <FlourLab formik={formik} labData={labData} language={language}/> : null
                                    : labFancyData[0] && labFancyData[0].lab_fluorescence ? <FlourLabFancy formik={formik} labFancyData={labFancyData} language={language}/> : null
                            }

                            {diamond_type === "N" ?
                                !fancy ?
                                    naturalData[0] && naturalData[0].lab ? <Lab formik={formik} naturalData={naturalData}  language={language}/>: null
                                    :  naturalFancyData[0] && naturalFancyData[0].lab ? <LabFancy formik={formik} naturalFancyData={naturalFancyData}  language={language}/> : null
                                : 
                                !fancyLab ?
                                    labData[0] && labData[0].lab_lab ? <LabLab formik={formik} labData={labData}  language={language}/>: null
                                    : labFancyData[0] && labFancyData[0].lab_lab ? <LabLabFancy formik={formik} labFancyData={labFancyData}  language={language}/> : null
                            }

                            {diamond_type === "N" ?
                                !fancy ?
                                   <Percentage formik={formik} naturalData={naturalData} language={language}/>
                                    : <PercentageFancy formik={formik} naturalFancyData={naturalFancyData} language={language}/>
                                : 
                                !fancyLab ?
                                    <PercentageLab formik={formik} labData={labData} language={language}/>
                                    :<PercentageLabFancy formik={formik} labFancyData={labFancyData} language={language}/>
                            }
                            {/* <Percentage formik={formik} labData={naturalData}/> */}
                        </Card.Body>
                    {/* </Accordion.Collapse> */}
                </Card>
            {/* </Accordion> */}
        </>
    );
};
