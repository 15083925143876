import React,{useState} from "react";
import { Field } from "formik";



export const Clarity =({formik,naturalData,language}) =>{
    const [clarityData] =useState(naturalData.length !== 0 && naturalData[0].clarity !== null ? naturalData[0].clarity : [])
    const newClarityData =clarityData.length!==0 ? clarityData.split(',').map((value,index) => {
        return {
            id: index,
            clarity:value
        }
    }):null

    return (
        <>
            <div className="row mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_CLARITY}</div>
                <div className="col-12" >
                    {newClarityData ? newClarityData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Clarity" id={`${item.clarity}_clarity`} value={item.clarity}/>
                            <div className="wClarityBox selectBox">
                                <span>{item.clarity}</span>
                            </div>
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
}