import React from "react";

export default function SortingTheme1(props) {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div>
            {/* <span className="w-100 mr-2">{props.language.CCMODE_THEME1_GRID_SORTBY}: {props.sorting ? capitalizeFirstLetter(props.sorting.split('_')[0]) : ""}</span> */}
            <select
                className="custom-select border border-primary rounded-0"
                onChange={(e) => props.setSorting(e.target.value)}
                value={props.sorting || ""}
                style={{height:"35px"}}
            >
                <option value="" disabled selected>
                    {props.language.CCMODE_THEME1_GRID_SORTBY}
                </option>
                <optgroup label="Carat">
                    <option selected={props.sorting === "carat_desc"} value="carat_desc">
                        high to low
                    </option>
                    <option selected={props.sorting === "carat_asc"} value="carat_asc">
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Color">
                    <option selected={props.sorting === "color_desc"} value="color_desc">
                        high to low
                    </option>
                    <option selected={props.sorting === "color_asc"} value="color_asc">
                        low to High
                    </option>
                </optgroup>
                <optgroup label="Clarity">
                    <option selected={props.sorting === "clarity_desc"} value="clarity_desc">
                        high to low
                    </option>
                    <option selected={props.sorting === "clarity_asc"} value="clarity_asc">
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Price">
                    <option selected={props.sorting === "price_desc"} value="price_desc">
                        high to low
                    </option>
                    <option selected={props.sorting === "price_asc"} value="price_asc">
                        low to high
                    </option>
                </optgroup>
            </select>
        </div>
    );
}
