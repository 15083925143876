import React from "react";
import { Field } from "formik";

export const PriceFancy = (props) => {

    function Currency(){
        if (props.themeData && props.themeData.api_currency === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }  else if (props.themeData && props.themeData.api_currency === "SGD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "THB"){
            const  currencyIcon = "฿"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "MYR"){
            const  currencyIcon = "RM"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "BND"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "DKK"){
            const  currencyIcon = "kr."
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "SEK"){
            const  currencyIcon = "kr"
            return currencyIcon
        }
    }
  
    return (
        <>
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6">{props.language.CCMODE_THEME1_SEARCHDIAMOND_PRICE}</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                       
                        <div className="col-md-6 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>Price</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Price"
                                        min="0"
                                        step="0.01"
                                        id="Min_Price"
                                        placeholder="From"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">{Currency()}</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Price"
                                        min="0"
                                        step="0.01"
                                        id="Max_Price"
                                        placeholder="To"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">{Currency()}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
