import React,{useState} from "react";
import { Field } from "formik";

export const LabLabFancy = ({labFancyData,language}) => {
    const [labResData,setLabResData] =useState(labFancyData.length !== 0 && labFancyData[0].lab_lab !== null ? labFancyData[0].lab_lab : [])

    const newLabData =labResData.length!==0 ? labResData.split(',').map((value,index) => {
        return {
            id: index,
            lab:value,
        }
    }):null 

    // const LabData = ([
    //     {
    //         id: 1,
    //         lab:"GIA"
    //     },
    //     {
    //         id: 2,
    //         lab:"IGI"
    //     },
    //     {
    //         id: 3,
    //         lab:"HRD"
    //     },
    //     {
    //         id: 4,
    //         lab:"OTHER"
    //     }
    // ]);
    return (
        <>
            <div className="row mt-5 pb-8 labdata border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6">{language.CCMODE_THEME1_SEARCHDIAMOND_LAB}</p>
                </div>
                <div className="col-lg-10">
                    {newLabData ? newLabData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Lab" id={`${item.lab}_lab`} value={item.lab}/>
                            <div className="labBox selectBox">
                                <span>{item.lab}</span>
                            </div>  
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
};