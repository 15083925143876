import React, { useEffect , useState } from "react";
import { Slider, TextField,makeStyles } from "@material-ui/core";
import axios from "axios";
import { NODE_API_URL } from "../../../../../../env_config";
// import { Field } from "formik";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";
import Cookies from "js-cookie";
const useStyles = makeStyles({
    TextField: {
      width: 80,
      margin:0
    },
  });

export const Price = ({formik,naturalData,language,location}) => {
    const classes = useStyles();
    const [minPrice, setMinPrice] = useState(location && location.Formik && typeof (location.Formik.minPrice) === "number"?location.Formik.minPrice:naturalData.length !== 0 && naturalData[0].total_price_from !== null ? naturalData[0].total_price_from : 0);
    const [maxPrice, setMaxPrice] = useState(location && location.Formik && typeof (location.Formik.maxPrice) === "number"?location.Formik.maxPrice:naturalData.length !== 0  && naturalData[0].total_price_to !== null ? naturalData[0].total_price_to : 99999);
    const [priceRangeValue, setPriceRangeValue] = useState([naturalData.length !== 0  && naturalData[0].total_price_from !== null ? naturalData[0].total_price_from : 0, naturalData.length !== 0  && naturalData[0].total_price_to !== null ? naturalData[0].total_price_to : 99999]);
    // const minPriceRange = naturalData.length !== 0 && naturalData[0].total_price_from !== null ? naturalData[0].total_price_from : 0;
    // const maxPriceRange = naturalData.length !== 0 && naturalData[0].total_price_to !== null ? naturalData[0].total_price_to : 99999;
    const [minPriceRange,setminPriceRange] = useState(0)
    const [maxPriceRange,setmaxPriceRange] = useState(99999)

    useEffect(() => {
        // formik.setFieldValue("minPrice", minPrice);
        // formik.setFieldValue("maxPrice", maxPrice);
        if(naturalData && naturalData.length){
            const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
            axios({
                method: "POST",
                url: `${NODE_API_URL}/iframe/fetchPriceRanges`,
                headers: { "Content-Type": "application/json","Parent-Domain":parentDomain },
                data: {
                    "user_id":naturalData[0].user_id,
                    "rule_id":naturalData[0].rule_id,
                    token:localStorage.getItem("auth")
                },
            })
            .then(res => {
                if(res && res.data){
                    if(location && location.Formik && typeof (location.Formik.minPrice) === "number" && location && location.Formik && typeof (location.Formik.maxPrice) === "number"){
                        formik.setFieldValue("minPrice", location.Formik.minPrice);
                        formik.setFieldValue("maxPrice", location.Formik.maxPrice);
                        setPriceRangeValue([location.Formik.minPrice,location.Formik.maxPrice])
                    }
                    else{
                        formik.setFieldValue("minPrice", res.data.minprice);
                        formik.setFieldValue("maxPrice", res.data.maxprice);
                        setPriceRangeValue([res.data.minprice,res.data.maxprice])
                    }
                    setminPriceRange(res.data.minprice)
                    setmaxPriceRange(res.data.maxprice)
                }
            })
        }
    }, [naturalData]);

    const handlePriceRangeChange = (event, newValue) => {
        setMinPrice(newValue[0]);
        setMaxPrice(newValue[1]);
        setPriceRangeValue(newValue);
        // formik.setFieldValue("minPrice", newValue[0]);
        // formik.setFieldValue("maxPrice", newValue[1]);
    };

    const handlePriceRangeChangeCommitted = (event, newValue) => {
        formik.setFieldValue("minPrice", newValue[0]);
        formik.setFieldValue("maxPrice", newValue[1]);
    };


    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_PRICE}</div>
                <div className="col-12">
                    <div className="row m-0 mt-5">
                        <div className="col-md-12">
                            <Slider
                                getAriaLabel={() => "Price Range"}
                                value={priceRangeValue}
                                onChangeCommitted={handlePriceRangeChangeCommitted}
                                onChange={handlePriceRangeChange}
                                step={10}
                                valueLabelDisplay="auto"
                                // getAriaValueText={priceRangeValue}
                                min={minPriceRange}
                                max={maxPriceRange}
                                color="secondary"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between">
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                name="minPrice"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={priceRangeValue[0]}
                                color="secondary"
                                type="number"
                                inputProps={{
                                    style: {
                                      padding: 5,
                                      textAlign:"center",
                                      height: "0.8em",
                                      fontSize:"11px"
                                    }
                                 }}
                                onChange={(e) => {
                                    setMinPrice(Number(e.target.value));
                                    setPriceRangeValue([
                                        Number(e.target.value),
                                        priceRangeValue[1],
                                    ]);
                                    formik.setFieldValue("minPrice", Number(e.target.value));
                                }}
                            />
                                
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                variant="outlined"
                                name="maxPrice"
                                InputLabelProps={{ shrink: true }}
                                value={ priceRangeValue[1]}
                                color="secondary"
                                type="number"
                                inputProps={{
                                    style: {
                                      padding: 5,
                                      textAlign:"center",
                                      height: "0.8em",
                                      fontSize:"11px"
                                     
                                    }
                                 }}
                                onChange={(e) => {
                                    setMaxPrice(Number(e.target.value));
                                    setPriceRangeValue([
                                        priceRangeValue[0],
                                        Number(e.target.value),
                                    ]);
                                    formik.setFieldValue("maxPrice", Number(e.target.value));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
