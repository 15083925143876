import Cookies from "js-cookie"
export async function GetCookies(key){
    if(key){
        const cookie = await Cookies.get(key)
        return cookie
    }
}
export async function SetCookies(key,value){
    if(key && value){
        const cookie = await Cookies.set(key,value)
        return cookie
    }
}
export async function RemoveCookies(key,value){
    if(key){
        const cookie = await Cookies.remove(key,value)
        return cookie
    }
}