import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NODE_API_URL } from "./../../../env_config";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
export const SendInquiry = (props) => {
    const [show, setShow] = useState(false);
    // const [ipData, setIpdata] = React.useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let color_detail = ""
    if  (props.diamond_details.COLOR){
        color_detail += props.diamond_details.COLOR;
    }else{
        if (props.diamond_details.FANCY_INTENSITY){
            color_detail += props.diamond_details.FANCY_INTENSITY;
            color_detail += " ";
        }
        if (props.diamond_details.FANCY_INTENSITY){
            color_detail += props.diamond_details.FANCY_OVERTONE;
            color_detail += " ";
        }
        if (props.diamond_details.FANCY_INTENSITY){
            color_detail += props.diamond_details.FANCY_COLOR;
            color_detail += " ";
        }
    }
    const initialValues = {
        fullName: "",
        emailAddress: "",
        contactNumber: "",
        diamondDetail: `#SKU ${props.diamond_details.STOCK_ID} ${props.diamond_details.CARAT ? parseFloat(props.diamond_details.CARAT).toFixed(2) : null}Ct. ${color_detail} ${props.diamond_details.CLARITY} ${props.diamond_details.CUT} ${props.diamond_details.POLISH} ${props.diamond_details.SYMMETRY} ${props.diamond_details.FLUORESCENCE}`,
        comment: "",
    };
    const Schema = Yup.object().shape({
        fullName: Yup.string().required("Full Name is required"),
        emailAddress: Yup.string().email("Wrong email format").required("email is Required"),
        contactNumber: Yup.string().required("contact number is Required"),
        // diamondDetail: Yup.string().required("contact number is Required"),
        comment: Yup.string().required("comment is Required").max(255, 'Comment must be at most 255 characters'),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
 
    // Get device information
    function detectDevice() {
        // Check if the user agent contains the substring "Mobi"
        return /Mobi/.test(navigator.userAgent) ? "Mobile" : "Pc";
    }

    function detectOS() {
        const userAgent = navigator.userAgent;
        if (/Windows/.test(userAgent)) {
            return "Windows";
        } else if (/Macintosh/.test(userAgent)) {
            return "Mac OS";
        } else if (/Linux/.test(userAgent)) {
            return "Linux";
        } else if (/Android/.test(userAgent)) {
            return "Android";
        } else if (/iPhone|iPad|iPod/.test(userAgent)) {
            return "iOS";
        } else {
            return "Unknown";
        }
    }
    
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting,resetForm  }) => {
            // Function to get public IP address
           
            const deviceType = detectDevice();
            const os = detectOS();

            const deviceInfo = `${deviceType} - ${os}`; // Combine device type and OS into a single field
            const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
            let diamond_details = {...props.diamond_details,MARKUP_PRICE_PER_CTS:props.diamond_details.PRICE_PER_CTS}
            delete diamond_details.language
            delete diamond_details.natData
            delete diamond_details.stoneParams
            delete diamond_details.themeData
            delete diamond_details.PRICE_PER_CTS
            axios({
                method: "POST",
                url: `${NODE_API_URL}/iframe/sendEnquiry`,
                headers: { 
                    "Content-Type": "application/json",
                    "Parent-Domain":parentDomain
                 },
                data: {
                    "ip":props.ipData.query,
                    "device":deviceInfo,
                    "Tag": "I",
                    "FullName":values.fullName,
                    "Email":values.emailAddress,
                    "ContactNumber":values.contactNumber,
                    "CustomerId": localStorage.getItem("user_id"),
                    "StockId": props.diamond_details.STOCK_ID,
                    "CertiNo": props.diamond_details.CERTIFICATE_NO,
                    "C_Weight":props.diamond_details.CARAT,
                    "C_Color":props.diamond_details.COLOR,
                    "C_Clarity":props.diamond_details.CLARITY,
                    "C_Cut":props.diamond_details.CUT,
                    "C_Polish":props.diamond_details.POLISH,
                    "C_Symmetry":props.diamond_details.SYMMETRY,
                    "C_Fluorescence":props.diamond_details.FLUORESCENCE,
                    "WLDiamondType": props.diamond_details.diamond_type,
                    "Currency": props.diamond_details.CURRENCY,
                    "CurrencyConversionRate": props.diamond_details.CURRENCY_RATE,
                    "MarkUpNatural": 0,
                    "MarkUpNaturalFancy": 0,
                    "MarkUpLab": 0,
                    "MarkUpLabFancy": 0,
                    "OurRate": props.diamond_details.calculateprice.T_C_Rate,
                    "OurPrice": props.diamond_details.calculateprice.total_our_price,
                    "MarkUpRate": props.diamond_details.PRICE_PER_CTS,
                    "MarkUpPrice": props.diamond_details.TOTAL_PRICE,
                    "CreatedAt": "0000-00-00 00:00:00",
                    "Tax": props.diamond_details.taxvalue,
                    "token":localStorage.getItem("auth"),
                    "MailOBJ":diamond_details,
                    "comment":values.comment
                },
            }).then(res => {
                if(res && res.data && (res.data.message === "Stone Shortlisted Successfully!" || res.data.message === "Stone Already Shortlisted")){
                    setShow(false)
                    if(res.data.message === "Stone Shortlisted Successfully!"){
                        toast.success("Thank you for your Inquiry, we will get back to you shortly! ")
                        // axios({
                        //     method: "POST",
                        //     url: `${NODE_API_URL}/iframe/storeUserACtivity`,
                        //     headers: { 
                        //         "Content-Type": "application/json",
                        //         "Parent-Domain":parentDomain
                        //     //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                        //      },
                        //     data: {
                        //         user_id:localStorage.getItem("user_id"),
                        //         activity_type:"IFrame Inquiry",
                        //         user_agent:navigator.userAgent,
                        //         referrer:parentDomain,
                        //         activity_data:{
                        //             ...diamond_details,
                        //             calculateprice:diamond_details.total_our_price,
                        //             "FullName":values.fullName,
                        //             "Email":values.emailAddress,
                        //             "ContactNumber":values.contactNumber,
                        //             "comment":values.comment
                        //         },
                        //         ip_address:props.ipData.query,
                        //         token:localStorage.getItem("auth")
                        //     }
                        // }).then(response => {
                        // }).catch(error => {
                        // })
                    }
                    if(res.data.message === "Stone Already Shortlisted"){
                        toast.success("Inquiry Already Sended, we will get back to you shortly!")
                    }
                    resetForm()
                }
            })
        }
    });

    return (
        <>
        {props.language?<div>
            <button
                onClick={() => handleShow()}
                className={`btn btn-sm btn-primary`}
            >
                {props.language.CCMODE_SHORTLISTPAGE_SEND_ENQUIRY}
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className={`${props.diamond_details.diamond_type === "N" ? "natTab" : "labTab"}`}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.language.CCMODE_SHORTLISTPAGE_SEND_ENQUIRY}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row" onSubmit={formik.handleSubmit}>
                        <div className="col-12">
                            <div className="form-group row mb-2">
                                <label className="col-12 col-form-label text-alert font-weight-bold pb-1">
                                    {props.language.CCMODE_SHORTLISTPAGE_FULL_NAME} <span className="small text-danger">*</span>
                                </label>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        placeholder={props.language.SESSION_POPUP_FIRSTNAME}
                                        className={`form-control ${getInputClasses(
                                            "fullName"
                                        )}`}
                                        name="fullName"
                                        {...formik.getFieldProps("fullName")}
                                    />
                                    {formik.touched.fullName &&
                                    formik.errors.fullName ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.fullName}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-12 col-form-label text-alert font-weight-bold pb-1">
                                    {props.language.CCMODE_SHORTLISTPAGE_EMAIL} <span className="small text-danger">*</span>
                                </label>
                                <div className="col-12">
                                    <input
                                        type="email"
                                        placeholder={props.language.SESSION_POPUP_FIRSTEMAIL}
                                        className={`form-control ${getInputClasses(
                                            "emailAddress"
                                        )}`}
                                        name="emailAddress"
                                        {...formik.getFieldProps("emailAddress")}
                                    />
                                    {formik.touched.emailAddress &&
                                    formik.errors.emailAddress ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.emailAddress}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-12 col-form-label text-alert font-weight-bold pb-1">
                                    {props.language.CCMODE_SHORTLISTPAGE_CONTACT} <span className="small text-danger">*</span>
                                </label>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        placeholder={props.language.SESSION_POPUP_CONTACTNO}
                                        className={`form-control ${getInputClasses(
                                            "contactNumber"
                                        )}`}
                                        name="contactNumber"
                                        {...formik.getFieldProps("contactNumber")}
                                    />
                                    {formik.touched.contactNumber &&
                                    formik.errors.contactNumber ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.contactNumber}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-12 col-form-label text-alert font-weight-bold pb-1">
                                    {props.language.CCMODE_SHORTLISTPAGE_DIAMOND_DETAIL} <span className="small text-danger">*</span>
                                </label>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        placeholder={props.language.SESSION_POPUP_DIAMOND_DETAIL}
                                        className={`form-control ${getInputClasses(
                                            "diamondDetail"
                                        )}`}
                                        readOnly
                                        name="diamondDetail"
                                        {...formik.getFieldProps("diamondDetail")}
                                    />
                                    {formik.touched.diamondDetail &&
                                    formik.errors.diamondDetail ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.diamondDetail}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-12 col-form-label text-alert font-weight-bold pb-1">
                                    {props.language.CCMODE_SHORTLISTPAGE_COMMENT} <span className="small text-danger">*</span>
                                </label>
                                <div className="col-12">
                                    <textarea
                                        placeholder={props.language.SESSION_POPUP_COMMENTS}
                                        className={`form-control ${getInputClasses(
                                            "comment"
                                        )}`}
                                        rows="4"
                                        name="comment"
                                        {...formik.getFieldProps("comment")}
                                    />
                                    {formik.touched.comment &&
                                    formik.errors.comment ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.comment}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 border-top pt-5 mt-5 d-flex justify-content-between">
                            <button
                                className="btn btn-secondary btn-sm mr-2"
                                onClick={handleClose}
                            >
                                {props.language.CCMODE_SHORTLISTPAGE_CLOSE}
                            </button>
                            <button
                                className="btn btn-primary btn-sm"
                            >
                                {props.language.CCMODE_SHORTLISTPAGE_SEND_ENQUIRY}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
               
            </Modal>
            </div>:""}
        </>
    );
};
