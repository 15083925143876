import React, { useEffect , useState } from "react";
import { Field } from "formik";
import {SetCookies,RemoveCookies,GetCookies} from '../../../../../_helpers/Cookies'

export const Carat = ({formik,language}) => {
    useEffect(() => {
        GetCookies("range").then((res) => {
    if(res && res === "1"){
        rangeClick()
    }
})
GetCookies("manual").then((res) => {
    if(res && res === "1"){
        manualClick()
    }
})
},[])
    const caratrangeData = ([
        {
            id: 1,
            caratrange:"0.18 - 0.22",
            name:"CaratRange1"
        },
        {
            id: 2,
            caratrange:"0.23 - 0.29",
            name:"CaratRange2"
        },
        {
            id: 3,
            caratrange:"0.30 - 0.39",
            name:"CaratRange3"
        },
        {
            id: 4,
            caratrange:"0.40 - 0.49",
            name:"CaratRange4"
        },
        {
            id: 5,
            caratrange:"0.50 - 0.69",
            name:"CaratRange5"
        },
        {
            id: 6,
            caratrange:"0.70 - 0.89",
            name:"CaratRange6"
        },
        {
            id: 7,
            caratrange:"0.90 - 0.99",
            name:"CaratRange7"
        },
        {
            id: 8,
            caratrange:"1.00 - 1.49",
            name:"CaratRange8"
        },
        {
            id: 9,
            caratrange:"1.50 - 1.99",
            name:"CaratRange9"
        },
        {
            id: 10,
            caratrange:"2.00 - 2.99",
            name:"CaratRange10"
        },
        {
            id: 11,
            caratrange:"3.00 - 3.99",
            name:"CaratRange11"
        },
        {
            id: 12,
            caratrange:"4.00 - 4.99",
            name:"CaratRange12"
        },
        {
            id: 13,
            caratrange:"5.00 - 9.99",
            name:"CaratRange13"
        },
        {
            id: 14,
            caratrange:"10+",
            name:"CaratRange14"
        }
    ]);
    const manualNav = React.useRef(null);
    const rangeNav = React.useRef(null);
    const manualBtn = React.useRef(null);
    const rangeBtn = React.useRef(null);
    

    const manualClick = (e) => {
        // var x = document.querySelectorAll("caratRange");
        //     for(let i=0; i<=x.length; i++) {
        //         document.addEventListener("DOMContentLoaded", function() {
        //             // document.getElementById("cbox0").checked = true;
        //             x[i].checked = false;
        //           });
        //     }   
        RemoveCookies("range")
        SetCookies("manual",1)
        formik.setFieldValue("CaratRange1","")
        formik.setFieldValue("CaratRange2","")
        formik.setFieldValue("CaratRange3","")
        formik.setFieldValue("CaratRange4","")
        formik.setFieldValue("CaratRange5","")
        formik.setFieldValue("CaratRange6","")
        formik.setFieldValue("CaratRange7","")
        formik.setFieldValue("CaratRange8","")
        formik.setFieldValue("CaratRange9","")
        formik.setFieldValue("CaratRange10","")
        formik.setFieldValue("CaratRange11","")
        formik.setFieldValue("CaratRange12","")
        manualNav.current.classList.add("show");
        rangeNav.current.classList.remove("show");
        manualBtn.current.classList.remove("text-primary");
        rangeBtn.current.classList.add("text-primary");
      };

    const rangeClick = (e) => {
        // const manualCarat = document.querySelectorAll(".manualCarat");
        // for (let i = 0; i < manualCarat.length; i++) {
        //     manualCarat[i].value = "";
        // }
        RemoveCookies("manual")
        SetCookies("range",1)
        formik.setFieldValue("Min_Carat","")
        formik.setFieldValue("Max_Carat","")
        manualNav.current.classList.remove("show");
        rangeNav.current.classList.add("show");
        manualBtn.current.classList.add("text-primary");
        rangeBtn.current.classList.remove("text-primary");
    };
    return (
        <>
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 m-0">{language.CCMODE_THEME1_SEARCHDIAMOND_CARAT}</p>
                    {/* <div className="">
                        <span className="font-size-lg cursor-pointer" onClick={manualClick} ref={manualBtn}>Manual</span> / &nbsp;
                        <span className="font-size-lg cursor-pointer text-primary" onClick={rangeClick} ref={rangeBtn}>Range</span>
                    </div> */}
                </div>
                <div className="col-lg-5 carat show" ref={manualNav}>
                    <div className="row form-group">
                        <div className="col-md-6 mb-md-0 mb-4">
                            <Field
                                className="form-control manualCarat mr-2"
                                type="number"
                                name="Min_Carat"
                                min="0"
                                step="0.01"
                                id="Min_Carat"
                                placeholder="From"
                                autoComplete="off"
                            />
                        </div>
                        <div className="col-md-6">
                            <Field
                                className="form-control manualCarat mr-2"
                                type="number"
                                name="Max_Carat"
                                min="0"
                                step="0.01"
                                id="Max_Carat"
                                placeholder="To"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-10 carat flex-wrap" ref={rangeNav}>
                    {caratrangeData.map((item) => (
                        <label key={item.id} className="search_btn_check" >
                            <Field type="checkbox" className="caratRange" name={item.name} id={item.name} value={item.caratrange} />
                            <div className="labBox selectBox">
                                <span>{item.caratrange}</span>
                            </div>
                        </label>
                    ))}
                </div> */}
            </div>
        </>
    );
};
