import React, {  useState } from "react";
import { Field } from "formik";
import {toAbsoluteUrl} from "../../../../../_helpers";
import SVG from "react-inlinesvg";
import {OverlayTrigger,Tooltip} from "react-bootstrap";

export const ShapeLab = ({labData,language}) => {
    const [shape] = useState( labData.length !== 0 && labData[0].lab_shape !== null ? labData[0].lab_shape : [] );

    const newShapeData =shape.length!==0?shape.split(',').map((value,index) => {
        return {
            id: index,
            shape:value,
            multilingualshape:language[`${value.toUpperCase()}`]
        }
    }):null

    return (
        <>
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6">{language.CCMODE_THEME1_SEARCHDIAMOND_SHAPE}</p>
                </div>
                <div className="col-lg-10">
                    {newShapeData ? newShapeData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Shape" id={`${item.shape.toLowerCase()}_shape`} value={item.shape}/>
                            {/* <span></span> */}
                            <div className="shapeBox selectBox">
                                <SVG src={toAbsoluteUrl(`/media/shape/svg_shape/${item.shape.toLowerCase()}.svg`)} />
                                <br/>
                                <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip" >{item.multilingualshape}</Tooltip>} >
                                    <span className="text-uppercase text-truncate">{item.multilingualshape}</span>
                                </OverlayTrigger>
                            </div>
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
};
