/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useState,useEffect } from "react";
import { Redirect, Switch, Route,useLocation  } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { GetCookies } from "../_metronic/_helpers/Cookies";
import axios from "axios";
import { NODE_API_URL } from "../env_config";
import Cookies from "js-cookie"
import languages from "../_metronic/layout/components/extras/languages.json" 
import { Loader } from "../_metronic/_partials/Loader";
// import LightColor from "./pages/Lightcolor";
import { useLighterColorLab } from "./pages/Lightcolor";
// import useLighterColorLab from "./pages/Lightcolor";
import {useLighterColorNatural} from "./pages/Lightcolor";
export function Routes() {
    const [theme, setTheme] = useState("loader");
    const [ipData, setIpdata] = useState("loader");
    const [loader, setLoader] = useState(true);
    const [isAuthorized,setAuthorized] = useState(true);
    const [themeData, setThemeData] = useState([]);
    const [ruleData, setRuleData] = useState([]);
    const [naturalData, setNaturalData] = useState([]);
    const [naturalFancyData, setNaturalFancyData] = useState([]);
    const [labData, setLabData] = useState([]);
    const [labFancyData, setLabFancyData] = useState([]);
    const [user, setUser] = useState([]);
    const [language,setLanguage] = React.useState("en")
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const auth = searchParams.get("auth")
    // const [lightColorHex, setLightColorHex] = useState('#ffffffc')
    const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
    useEffect(() => {
      // ------------------------------temp set response for auto login
         let resData = {"CustomerId":52027,"NaturalPrimaryColor":"#3699FF","NaturalFontColor":"#ffffff","LabPrimaryColor":"#00c897","LabFontColor":"#ffffff","Currency":"USD","TaxName":"","TaxValue":0,"Theme":"theme2","created_date":"2024-02-03T00:00:00.000Z","Logo":"1707292410583-1.png","Password":"$2a$10$qejxcScO/yRPXFoU65iTqe6YYQiKK0HVXeREzanB80n/hJV4F/dEW","AllowLogin":{"type":"Buffer","data":[1]},"ccmode_plan":"undefined","timeZone":"Asia/Calcutta","cname":"Divyang  Testing","mail":"divyang@diamondsoncall.com"}

        // Cookies.set('resData', JSON.stringify(resData), { sameSite: 'None', secure: true });
      // ------------------------------ end of temp set response for auto login
      
      if (isAuthorized){
            axios({
              method: "POST",
              url: `${NODE_API_URL}/iframe/fetchIframe`,
              // url: `https://api.staging.global.diamondsupload.com/INTL/fetchCCModeSetting`,
              headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  "Parent-Domain":parentDomain
              },
              data: {
                CustomerId:localStorage.getItem("user_id"),
                token:localStorage.getItem("auth")
              }
            })
            .then(function(res) {
              if(res && res.data && res.data.data){
                  setThemeData(res.data.data)
                  setTheme(res.data.data.ifm_theme)
                  setLanguage(res.data.data.ifm_multilingual)
                  // Cookies.set('language', res.data.data.ifm_multilingual, { sameSite: 'None', secure: true });
                  localStorage.setItem('language', res.data.data.ifm_multilingual);
              }
              
            })
            .catch((res) => {
            });

            axios({
                method: 'GET',
                url:"https://api.ipify.org",
            })
              .then(function (res) {
                  setIpdata({query:res.data})
              })
              .catch((res) => {
                
              });

            // for rules 

            axios({
              method: "POST",
              url: `${NODE_API_URL}/iframe/fetchRule`,
              // url: `https://api.staging.global.diamondsupload.com/INTL/fetchCCModeSetting`,
              headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  "Parent-Domain":parentDomain
              },
              data: {
                user_id:localStorage.getItem("user_id"),
                token:localStorage.getItem("auth")
              }
            })
            .then(function(res) {
              if(res && res.data){
                setLoader(false)
                setRuleData(res.data.data)

                setNaturalData(
                    res.data.data.find(element => element.naturaldiamond === 1) !== undefined 
                    ? [res.data.data.find(element => element.naturaldiamond === 1) ]
                    : []
                  )

                setNaturalFancyData(
                    res.data.data.find(element => element.naturalfancydiamond === 1) !== undefined 
                    ? [res.data.data.find(element => element.naturalfancydiamond === 1)] 
                    : []
                  )

                setLabData(
                    res.data.data.find(element => element.labdiamond === 1) !== undefined ? 
                    [res.data.data.find(element => element.labdiamond === 1)] 
                    : []
                  )

                setLabFancyData(
                    res.data.data.find(element => element.labfancydiamond === 1) !== undefined 
                    ? [res.data.data.find(element => element.labfancydiamond === 1)] 
                    :[]
                  )
                
              }
            })
            .catch((res) => {
                console.warn(res);
            });
          
      //   GetCookies("language").then(response => {  
      //     if(response){
      //         setLanguage(response)
      //     }
      // })
      }
     
    }, [isAuthorized,localStorage.getItem("auth")]);
    useEffect(() => {
      if(auth){
        // SetCookies("auth",auth)
        // Cookies.set('auth', auth, { sameSite: 'None', secure: true });
        localStorage.setItem('auth', auth)
      }
    },[auth])
    useEffect(() => {
      if(!localStorage.getItem("auth")){
        setAuthorized(false)
        // SetCookies("auth","")
        // Cookies.set('auth', "", { sameSite: 'None', secure: true });
        localStorage.setItem('auth', "")
        return
      }
      axios({
        method: "POST",
        url: `${NODE_API_URL}/iframe/Authenticator`,
        // url: `https://api.staging.global.diamondsupload.com/INTL/fetchCCModeSetting`,
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Parent-Domain":parentDomain
        },
        data: {
          token:localStorage.getItem("auth")
        }
      }).then(iframeres => {
          if(iframeres && iframeres.data && iframeres.data.isauth){
            setAuthorized(true)
            // Cookies.set('user_id', iframeres.data.user_id, { sameSite: 'None', secure: true });
            localStorage.setItem('user_id', iframeres.data.user_id)
          }
          else{
            setAuthorized(false)
          }
      }).catch(error => setAuthorized(false))
    },[localStorage.getItem("auth")])

    useEffect(() => {
      if(naturalData.length !== 0 && naturalData[0].status !== 0){
        document.body.classList.add('natTab');
      } else if (labData.length !== 0 && labData[0].status !== 0){
        document.body.classList.add('labTab');
      }
    },[labData,naturalData]);

    const primaryColorLab =(themeData.length !== 0 && themeData.ifm_LabPrimaryColor) ? themeData.ifm_LabPrimaryColor : "#ffffff";
    const primaryColorNatural =(themeData.length !== 0 && themeData.ifm_NaturalPrimaryColor) ? themeData.ifm_NaturalPrimaryColor : "#ffffff";
    // const primaryColor ="#002173";
    const lightenAmount = 220; // Adjust this value to control the lightness
  
    const lighterHexNatural = useLighterColorNatural(primaryColorNatural, lightenAmount);
    const lighterHexLab = useLighterColorLab(primaryColorLab, lightenAmount);
  
    // useEffect(() => {
    //   console.log('Lighter Hex Lab:', lighterHexLab);
    //   console.log('Lighter Hex Natural:', lighterHexNatural);
    // }, [lighterHexLab,lighterHexNatural]);
    return (
        <>
            <style>
                {`
            
                body::-webkit-scrollbar {
                  height: 10px;
                  width:  10px;
                }
                /*Track */
                body::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey;
                }
                /*Handle */
                body.natTab::-webkit-scrollbar-thumb {
                    background: ${themeData.ifm_NaturalPrimaryColor}42;
                    border-radius: 10px;
                    box-shadow: inset 0 0 5px 3px ${themeData.ifm_NaturalPrimaryColor};
                }
                body.natTab::-webkit-scrollbar-thumb:hover {
                    background: ${themeData.ifm_NaturalPrimaryColor}8f;
                    scrollbar-color: ${themeData.ifm_NaturalPrimaryColor}8f rgb(0 33 115 / 56%);
                }
                .natTab .scrolltop{
                  background-color: ${themeData.ifm_NaturalPrimaryColor};
                }
                .table-responsive::-webkit-scrollbar {
                  height: 10px;
                  width:  10px;
                }
                /*Track */
                .table-responsive::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey;
                }
                .natTab p, .natTab div, .natTab label, .natTab span, .natTab .custom_datatable td, .natTab .custom_datatable td span, .natTab .table{
                  color: ${themeData.ifm_NaturalFontColor};
                }
                .natTab .custom_datatable td g [fill] {
                  stroke: ${themeData.ifm_NaturalFontColor}!important;
                }
                .natTab .input-group-text{
                  background-color: ${themeData.ifm_NaturalPrimaryColor}15;
                  color: ${themeData.ifm_NaturalFontColor};
                }
                .table-responsive::-webkit-scrollbar-thumb {
                    background: ${themeData.ifm_NaturalPrimaryColor}42;
                    border-radius: 10px;
                    box-shadow: inset 0 0 5px 3px ${themeData.ifm_NaturalPrimaryColor};
                }
                .table-responsive::-webkit-scrollbar-thumb:hover {
                    background: ${themeData.ifm_NaturalPrimaryColor}8f;
                    scrollbar-color: ${themeData.ifm_NaturalPrimaryColor}8f rgb(0 33 115 / 56%);
                }
                .natTab .search_accordion .card .card-header {
                    background-color: ${themeData.ifm_NaturalPrimaryColor}!important;
                    color: ${themeData.ifm_NaturalFontColor};
                }
                .natTab .search_btn_check > input:checked ~ .selectBox {
                    background-color:${themeData.ifm_NaturalPrimaryColor};
                    color: ${lighterHexNatural};
                }
                .natTab .search_btn_check > input:checked ~ .selectBox span{
                  color: ${lighterHexNatural}!important;
                }
                .natTab .search_btn_check .selectBox span{
                  color: ${themeData.ifm_NaturalFontColor}!important;
                }
                
                .natTab .search_btn_check > input:checked ~ .selectBox g [fill] {
                  stroke: ${lighterHexNatural}!important;
                }
                .natTab .search_btn_check > .selectBox svg g [fill] {
                  stroke: ${themeData.ifm_NaturalFontColor}!important;
                }
                .theme3 .natTab .search_btn_check > .selectBox{
                  background-color: ${themeData.ifm_NaturalPrimaryColor}15;
                  border: 1px solid #00217300;
                }
                .natTab .selectBox:hover ,.theme3 .natTab .selectBox:hover{
                  border: 1px solid ${themeData.ifm_NaturalPrimaryColor}!important;
                }
                .natTab .search_btn_check > .selectBox {
                    color: ${themeData.ifm_NaturalFontColor};
                    background-color: ${themeData.ifm_NaturalPrimaryColor}10;
                    border: 1px solid ${themeData.ifm_NaturalPrimaryColor}40;
                }
                .natTab .search_btn_check > input:checked ~ .selectBox span {
                  color: ${lighterHexNatural};
                }
                .natTab .text-primary{color: ${themeData.ifm_NaturalPrimaryColor}!important;}
                .natTab.text-primary{color: ${themeData.ifm_NaturalPrimaryColor}!important;}
                .natTab .text-dark{color: ${themeData.ifm_NaturalFontColor}!important;}
                .natTab.text-dark{color: ${themeData.ifm_NaturalFontColor}!important;}
                .natTab .text-dark-50{color: ${themeData.ifm_NaturalFontColor}50!important;}
                .natTab.text-dark-50{color: ${themeData.ifm_NaturalFontColor}50!important;}
                .natTab .btn.btn-primary {
                  color:${lighterHexNatural}!important;
                  background-color: ${themeData.ifm_NaturalPrimaryColor};
                  border-color: ${themeData.ifm_NaturalPrimaryColor};
                }
                .natTab .btn.btn-secondary{
                  color:${themeData.ifm_NaturalFontColor}!important;
                }

                .natTab .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .natTab .btn.btn-primary:focus:not(.btn-text),  .natTab .btn.btn-primary.focus:not(.btn-text) {
                  color:${lighterHexNatural}!important!important;
                  background-color: ${themeData.ifm_NaturalPrimaryColor}cc;
                  border-color: ${themeData.ifm_NaturalPrimaryColor}cc;
                }

                .natTab .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .natTab .btn.btn-primary:not(:disabled):not(.disabled).active,  .natTab .show > .btn.btn-primary.dropdown-toggle, .natTab .show .btn.btn-primary.btn-dropdown {
                  color:${lighterHexNatural}!important;
                  background-color: ${themeData.ifm_NaturalPrimaryColor}cc;
                  border-color: ${themeData.ifm_NaturalPrimaryColor}cc;
                }
                .natTab .MuiSlider-colorSecondary {
                    color: ${themeData.ifm_NaturalPrimaryColor};
                }
                .natTab .MuiSlider-valueLabel span span {
                    color: ${themeData.ifm_NaturalFontColor};
                }
                .natTab .MuiTabs-indicator{ background-color: ${themeData.ifm_NaturalPrimaryColor};}
                .natTab .custom-seprator{
                  border-top: 1px solid  ${themeData.ifm_NaturalPrimaryColor};
                }
                .natTab .custom-result-box{
                    color: ${themeData.ifm_NaturalFontColor}!important;
                    background-color: ${themeData.ifm_NaturalPrimaryColor}14 !important;
                    border-color: ${themeData.ifm_NaturalPrimaryColor} !important;
                }
                .natTab .MuiTab-textColorSecondary.Mui-selected{
                  color: ${themeData.ifm_NaturalPrimaryColor};
                }
                .natTab .stone-detail-carat-box {
                  background-color: ${themeData.ifm_NaturalPrimaryColor}10;
                  background: linear-gradient(357deg, ${themeData.ifm_NaturalPrimaryColor}4d 0%, ${themeData.ifm_NaturalPrimaryColor}0c, ${themeData.ifm_NaturalPrimaryColor}0c 100%);
                }
                .natTab .stone-detail-carat-box .point .arrow {
                  border-top-color: ${themeData.ifm_NaturalPrimaryColor};
                }
                .natTab .bg-primary-custom-light {
                  background-color: ${themeData.ifm_NaturalPrimaryColor}30;
                }
                .natTab .bg-primary-custom {
                  background-color: ${themeData.ifm_NaturalPrimaryColor};
                }
                .natTab .bg-primary-custom-light {
                  background-color: ${themeData.ifm_NaturalPrimaryColor}30;
                }
                .natTab .bg-primary-custom {
                  background-color: ${themeData.ifm_NaturalPrimaryColor};
                }
                .natTab .border-primary {
                  border-color: ${themeData.ifm_NaturalPrimaryColor}!important;
                }
                .natTab .custom-select{
                  color: ${themeData.ifm_NaturalFontColor}!important;
                }
                .natTab.modal .modal-header .modal-title{color: ${themeData.ifm_NaturalPrimaryColor}!important;}




                /*Handle */
                body.labTab::-webkit-scrollbar-thumb {
                    background: ${themeData.ifm_LabPrimaryColor}42;
                    border-radius: 10px;
                    box-shadow: inset 0 0 5px 3px ${themeData.ifm_LabPrimaryColor};
                }
                body.labTab::-webkit-scrollbar-thumb:hover {
                    background: ${themeData.ifm_LabPrimaryColor}8f;
                    scrollbar-color: ${themeData.ifm_LabPrimaryColor}8f rgb(0 33 115 / 56%);
                }
                .labTab .scrolltop{
                  background-color: ${themeData.ifm_LabPrimaryColor};
                }
                .labTab p, .labTab div, .labTab label, .labTab span, .labTab .custom_datatable td, .labTab .custom_datatable td span, .labTab .table{
                  color: ${themeData.ifm_LabFontColor};
                }
                .labTab .custom_datatable td g [fill] {
                  stroke: ${themeData.ifm_LabFontColor}!important;
                }
                .labTab .input-group-text{
                  background-color: ${themeData.ifm_LabPrimaryColor}15;
                  color: ${themeData.ifm_LabFontColor};
                }
                .labTab .search_btn_check > input:checked ~ .selectBox g [fill] {
                  stroke: ${lighterHexLab}!important;
                }
                .labTab .search_btn_check > .selectBox svg g [fill] {
                  stroke: ${themeData.ifm_LabFontColor}!important;
                }
                .labTab .search_btn_check .selectBox span{
                  color: ${themeData.ifm_LabFontColor}!important;
                }
                .labTab .search_btn_check > input:checked ~ .selectBox span{
                  color: ${lighterHexLab}!important;
                }
                .labTab .search_accordion .card .card-header {
                  background-color: ${themeData.ifm_LabPrimaryColor}!important;
                  color: ${themeData.ifm_LabFontColor};
                }
                .labTab .search_btn_check > input:checked ~ .selectBox {
                    background-color:${themeData.ifm_LabPrimaryColor};
                    color: ${lighterHexLab};
                }
                theme3 .labTab .search_btn_check > input:checked ~ .selectBox {
                  background-color:${themeData.ifm_LabPrimaryColor}!important;
                  border-radius:8px;
                }
                .labTab .selectBox:hover ,.theme3 .labTab .selectBox:hover{
                    border: 1px solid ${themeData.ifm_LabPrimaryColor}!important;
                }
                .labTab .search_btn_check > .selectBox {
                    background-color: ${themeData.ifm_LabPrimaryColor}10;
                    border: 1px solid ${themeData.ifm_LabPrimaryColor}40;
                    color: ${themeData.ifm_LabPrimaryColor}
                }
                .theme3 .labTab .search_btn_check > .selectBox{
                  background-color: ${themeData.ifm_LabPrimaryColor}15;
                  border: 1px solid #00217300;
                }
                .labTab .search_btn_check > input:checked ~ .selectBox span {
                  color: ${lighterHexLab};
                }

                .labTab .search_btn_check > input:checked ~ .selectBox g [fill] {
                  stroke: ${lighterHexLab};
                }

                .labTab .search_btn_check > input:checked ~ .selectBox g rect[fill] {
                  stroke: ${themeData.ifm_LabFontColor}00;
                }
                .labTab .text-primary{color: ${themeData.ifm_LabPrimaryColor}!important;}
                .labTab.text-primary{color: ${themeData.ifm_LabPrimaryColor}!important;}
                .labTab .text-dark{color: ${themeData.ifm_LabFontColor}!important;}
                .labTab.text-dark{color: ${themeData.ifm_LabFontColor}!important;}
                .labTab .text-dark-50{color: ${themeData.ifm_LabFontColor}50!important;}
                .labTab.text-dark-50{color: ${themeData.ifm_LabFontColor}50!important;}
                .labTab .btn.btn-primary {
                  color:${lighterHexLab}!important;
                  background-color: ${themeData.ifm_LabPrimaryColor};
                  border-color: ${themeData.ifm_LabPrimaryColor};
                }
                .labTab .btn.btn-secondary{
                  color:${themeData.ifm_LabFontColor}!important;
                }
                .labTab .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .labTab .btn.btn-primary:focus:not(.btn-text),  .labTab .btn.btn-primary.focus:not(.btn-text) {
                  color:${lighterHexLab}!important;
                  background-color: ${themeData.ifm_LabPrimaryColor}cc;
                  border-color: ${themeData.ifm_LabPrimaryColor}cc;
                }
                

                .labTab .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .labTab .btn.btn-primary:not(:disabled):not(.disabled).active,  .labTab .show > .btn.btn-primary.dropdown-toggle, .labTab .show .btn.btn-primary.btn-dropdown {
                  color:${lighterHexLab}!important;
                  background-color: ${themeData.ifm_LabPrimaryColor}cc;
                  border-color: ${themeData.ifm_LabPrimaryColor}cc;
                }
                .labTab .MuiSlider-colorSecondary {
                    color: ${themeData.ifm_LabPrimaryColor};
                }
                .labTab .MuiTabs-indicator{ background-color: ${themeData.ifm_LabPrimaryColor};}
                .labTab .custom-seprator{
                  border-top: 1px solid  ${themeData.ifm_LabPrimaryColor};
                }
                .labTab .custom-result-box{
                  color: ${themeData.ifm_LabFontColor}!important;
                  background-color: ${themeData.ifm_LabPrimaryColor}14 !important;
                  border-color: ${themeData.ifm_LabPrimaryColor} !important;
                }
                .labTab .MuiTab-textColorSecondary.Mui-selected{
                  color: ${themeData.ifm_LabFontColor};
                }
                .labTab .MuiSlider-valueLabel span span {
                  color: ${themeData.ifm_LabFontColor};
                }
                .labTab .stone-detail-carat-box {
                  background-color: ${themeData.ifm_LabPrimaryColor}10;
                  background: linear-gradient(357deg, ${themeData.ifm_LabPrimaryColor}4d 0%, ${themeData.ifm_LabPrimaryColor}0c, ${themeData.ifm_LabPrimaryColor}0c 100%);
                }
                .labTab .stone-detail-carat-box .point .arrow {
                  border-top-color: ${themeData.ifm_LabPrimaryColor};
                }
                .labTab .bg-primary-custom-light {
                  background-color: ${themeData.ifm_LabPrimaryColor}30;
                }
                .labTab .bg-primary-custom {
                  background-color: ${themeData.ifm_LabPrimaryColor};
                }
                .labTab .bg-primary-custom-light {
                  background-color: ${themeData.ifm_LabPrimaryColor}30;
                }
                .labTab .bg-primary-custom {
                  background-color: ${themeData.ifm_LabPrimaryColor};
                }
                .labTab .border-primary {
                  border-color: ${themeData.ifm_LabPrimaryColor}!important;
                }
                .labTab .custom-select{
                  color: ${themeData.ifm_LabFontColor}!important;
                }
                
                .labTab.modal .modal-header .modal-title{color: ${themeData.ifm_LabFontColor}!important;}
            `}
            </style>
            {/* <LightColor setLightColorHex/> */}
            <Switch>
                {!isAuthorized ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <div className="parent-container">
                        <div className="card card-custom shadow-none card-sticky gutter-b">
                            <div className="card-body text-center">
                              <span className="card-label text-primary font-size-h2 font-weight-bold mb-5">
                                  Something Went Wrong
                              </span>
                                <div className="font-size-lg mt-3">Error Code: 400</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
                )}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={Logout} />

                {!isAuthorized ? (
                    /*Redirect to `/auth` when user is not authorized*/
                    <Redirect to="/" />
                ) : (
                  <>
                    { loader ? <Loader /> 
                    :  themeData && themeData.api !== 1?
                      <div className="parent-container">
                          <div className="card card-custom shadow-none card-sticky gutter-b">
                              <div className="card-body text-center">
                                <span className="card-label text-primary font-size-h2 font-weight-bold mb-5">
                                    Something Went Wrong
                                </span>
                                  <div className="font-size-lg mt-3">Error Code: 403</div>
                              </div>
                          </div>
                      </div>
                      :!ruleData?
                          <div className="parent-container">
                              <div className="card card-custom shadow-none card-sticky gutter-b">
                                  <div className="card-body text-center">
                                    <span className="card-label text-primary font-size-h2 font-weight-bold mb-5">
                                        Something Went Wrong
                                    </span>
                                      <div className="font-size-lg mt-3">Error Code: 204</div>
                                  </div>
                              </div>
                          </div>
                      :
                      ruleData.map(value => value.status).every(element => element === 0)?
                        <div className="parent-container">
                          <div className="card card-custom shadow-none card-sticky gutter-b">
                              <div className="card-body text-center">
                                <span className="card-label text-primary font-size-h2 font-weight-bold mb-5">
                                    Something Went Wrong
                                </span>
                                  <div className="font-size-lg mt-3">Error Code: 403</div>
                              </div>
                          </div>
                      </div>
                      : (<Layout language={languages[language]}>
                          <BasePage 
                            ipData={ipData}
                            themeData={themeData} 
                            theme={theme} 
                            user={user} 
                            ruleData={ruleData} 
                            naturalData={naturalData} 
                            naturalFancyData={naturalFancyData} 
                            labData={labData} 
                            labFancyData={labFancyData} 
                            language={languages[language]}/>
                      </Layout>)
                      }
                  </>
                )}
            </Switch>
        </>
    );
}
