import React, { useState } from "react";
import { BsFillCameraVideoFill } from "react-icons/bs";
import VideoIframe from "./VideoIframe";
export const VideoPopup = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <BsFillCameraVideoFill
                className="cursor-pointer text-primary mx-2 text-center"
                onClick={() => handleShow()}
            />

            <VideoIframe
                showVideo={show}
                handleClose={handleClose}
                src={
                    props.diamond_type === "N"
                        ? `https://pro360video.com/video.php?refno=${props.CERTIFICATE_NO}`
                        : `https://pro360video.com/labgrown.php?refno=${props.CERTIFICATE_NO}`
                }
            />
        </>
    );
};
