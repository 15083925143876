import { useState, useEffect } from 'react';

export function useLighterColorNatural(primaryColorNatural, lightenAmount) {
  const [lighterHexNatural, setLighterHexNatural] = useState('');

  useEffect(() => {
      const hexToRgb = (hex) => {
          const r = parseInt(hex.slice(1, 3), 16);
          const g = parseInt(hex.slice(3, 5), 16);
          const b = parseInt(hex.slice(5, 7), 16);
          return { r, g, b };
        };
        
        const rgbToHex = (rgb) => {
            return '#' + ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1);
        };
        
        
        const primaryRgb = hexToRgb(primaryColorNatural);
        const lighterColor = {
            r: Math.min(primaryRgb.r + lightenAmount, 255),
            g: Math.min(primaryRgb.g + lightenAmount, 255),
            b: Math.min(primaryRgb.b + lightenAmount, 255),
        };
        
        const lighterHexNatural = rgbToHex(lighterColor);
        
        setLighterHexNatural(lighterHexNatural);
        // console.log("hexToRg",hexToRgb(primaryColorNatural))
        // console.log("rgbToHex",rgbToHex(primaryColorNatural))
        // console.log("primaryColor",primaryColorNatural)
  }, [primaryColorNatural, lightenAmount]);

  return lighterHexNatural;
}

export function useLighterColorLab(primaryColorLab, lightenAmount) {
    const [lighterHexLab, setLighterHexLab] = useState('');
  
    useEffect(() => {
        const hexToRgb = (hex) => {
            const r = parseInt(hex.slice(1, 3), 16);
            const g = parseInt(hex.slice(3, 5), 16);
            const b = parseInt(hex.slice(5, 7), 16);
            return { r, g, b };
          };
          
          const rgbToHex = (rgb) => {
              return '#' + ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1);
          };
          
          
          const primaryRgb = hexToRgb(primaryColorLab);
          const lighterColor = {
              r: Math.min(primaryRgb.r + lightenAmount, 255),
              g: Math.min(primaryRgb.g + lightenAmount, 255),
              b: Math.min(primaryRgb.b + lightenAmount, 255),
          };
          
          const lighterHexLab = rgbToHex(lighterColor);
          
          setLighterHexLab(lighterHexLab);
        //   console.log("hexToRg",hexToRgb(primaryColorLab))
        //   console.log("rgbToHex",rgbToHex(primaryColorLab))
        //   console.log("primaryColor",primaryColorLab)
    }, [primaryColorLab, lightenAmount]);
  
    return lighterHexLab;
}