import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../_helpers";

function FancyColorBlock({ fancycolor, intensity }) {
    useEffect(() => {
        const fancyColorIntensityElements = document.querySelectorAll(
            '[data-toggle="fancy-color-intesity"]'
        );
        fancyColorIntensityElements.forEach((element) => {
            const colorIntensity = element.getAttribute("data-color-intesity");
            const leftPercent = getLeftPercent(colorIntensity);
            const width = (window.innerWidth - 30) * (leftPercent / 100);
            if (width > 70) {
                element.style.left = leftPercent + "%";
                element.querySelector(".arrow").style.display = "block";
            } else {
                element.style.left = leftPercent + "%";
                element.querySelector(".arrow").style.display = "block";
            }
        });
    }, []);

    const getLeftPercent = (intensity) => {
        switch (intensity) {
            case "FAINT":
                return 4;
            case "VERY LIGHT":
                return 14;
            case "LIGHT":
                return 25;
            case "FANCY LIGHT":
                return 36.5;
            case "FANCY":
                return 48;
            case "FANCY INTENSE":
                return 60;
            case "FANCY VIVID":
                return 72.5;
            case "FANCY DEEP":
                return 85.5;
            case "FANCY DARK":
                return 95.5;
            default:
                return 0;
        }
    };

    return (
        <div className="stone-detail-carat-box fancy-color">
            <p>
                <b>Color: {fancycolor}</b>
            </p>
            <div
                className="point mb-6"
                data-toggle="fancy-color-intesity"
                data-color-intesity={intensity}
            >
                <span className="arrow" style={{ display: "none" }}></span>
            </div>
            <div className="mt-2" data-fancycolor={fancycolor}>
                <img
                    src={toAbsoluteUrl(
                        `/media/General/detail/${fancycolor.toLowerCase()}-color.png`
                    )}
                    id={`${fancycolor.toLowerCase()}-fancy-color-img`}
                    className="w-100 mt-4"
                    alt="Color Range"
                />
                {/* <img
                    src={`/assets/plugin/images/${color.toLowerCase()}-color.png`}
                    className="w-100"
                    id={`${color.toLowerCase()}-fancy-color-img`}
                    style={{ display: "none" }}
                    alt={`${color} Fancy Color`}
                /> */}
            </div>
        </div>
    );
}

export default FancyColorBlock;
