import React, { useEffect , useState } from "react";
import { Slider, TextField,makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    TextField: {
      width: 80,
      margin:0
    },
  });

export const Carat = ({formik,naturalData,language,location}) => {
    const classes = useStyles();
    const [minCarat, setMinCarat] = useState(location && location.Formik && typeof (location.Formik.minCarat) === "number"?location.Formik.minCarat:naturalData.length !== 0 && naturalData[0].min_carat !== null ? naturalData[0].min_carat : "0");
    const [maxCarat, setMaxCarat] = useState(location && location.Formik && typeof (location.Formik.maxCarat) === "number"?location.Formik.maxCarat:naturalData.length !== 0 && naturalData[0].max_carat !== null ? naturalData[0].max_carat : "30");
    const [caratRangeValue, setCaratRangeValue] = useState([location && location.Formik && typeof (location.Formik.minCarat) === "number"?location.Formik.minCarat:naturalData.length !== 0 && naturalData[0].min_carat !== null ? naturalData[0].min_carat : "0", location && location.Formik && typeof (location.Formik.maxCarat) === "number"?location.Formik.maxCarat:naturalData.length !== 0 && naturalData[0].max_carat !== null ? naturalData[0].max_carat : "30"]);
    const minCaratRange = naturalData.length !== 0 && naturalData[0].min_carat !== null ? naturalData[0].min_carat : "0";
    const maxCaratRange = naturalData.length !== 0 && naturalData[0].max_carat !== null ? naturalData[0].max_carat : "30";

    useEffect(() => {
        formik.setFieldValue("minCarat", minCarat);
        formik.setFieldValue("maxCarat", maxCarat);
    }, []);

    const handleCaratRangeChange = (event, newValue) => {
        setMinCarat(newValue[0]);
        setMaxCarat(newValue[1]);
        setCaratRangeValue(newValue);
        // formik.setFieldValue("minCarat", newValue[0]);
        // formik.setFieldValue("maxCarat", newValue[1]);
    };

    const handleCaratRangeChangeCommitted = (event, newValue) => {
        formik.setFieldValue("minCarat", newValue[0]);
        formik.setFieldValue("maxCarat", newValue[1]);
    };
    
    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_CARAT}</div>
                <div className="col-12">
                    <div className="row m-0 mt-5">
                        <div className="col-md-12">
                            <Slider
                                getAriaLabel={() => "Carat Range"}
                                value={caratRangeValue}
                                onChangeCommitted={handleCaratRangeChangeCommitted}
                                onChange={handleCaratRangeChange}
                                step={0.01}
                                valueLabelDisplay="auto"
                                // getAriaValueText={caratRangeValue}
                                min={minCaratRange}
                                max={maxCaratRange}
                                color="secondary"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between">
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                name="minCarat"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={caratRangeValue[0]}
                                color="secondary"
                                type="number" // Change type to "number"
                                inputProps={{
                                    step: "any", // Allow any step value
                                    style: {
                                        padding: 5,
                                        textAlign: "center",
                                        height: "0.8em",
                                        fontSize: "11px"
                                    }
                                }}
                                 onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (!isNaN(newValue)) { // Validate if the entered value is a valid number
                                        setMinCarat(newValue);
                                        setCaratRangeValue([
                                            newValue,
                                            caratRangeValue[1],
                                        ]);
                                        formik.setFieldValue("minCarat", newValue);
                                    }
                                }}
                            />
                                
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                variant="outlined"
                                name="maxCarat"
                                InputLabelProps={{ shrink: true }}
                                value={ caratRangeValue[1]}
                                color="secondary"
                                type="number" // Change type to "number"
                                inputProps={{
                                    step: "any", // Allow any step value
                                    style: {
                                        padding: 5,
                                        textAlign: "center",
                                        height: "0.8em",
                                        fontSize: "11px"
                                    }
                                }}
                                 onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (!isNaN(newValue)) { // Validate if the entered value is a valid number
                                        setMaxCarat(newValue);
                                        setCaratRangeValue([
                                            caratRangeValue[0],
                                            newValue,
                                        ]);
                                        formik.setFieldValue("maxCarat", newValue);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
