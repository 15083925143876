import { openDB, deleteDB, wrap, unwrap } from 'idb';
const version = 1
const storeName = "jsondata"
const dbName = "searchdiamonds"
export async function StoreDiamondDB(data){
    if(data && data.length !==0){
        const db = await openDB(dbName,version)
        const updatedata = await db.transaction(storeName,"readwrite").objectStore(storeName).put(data,"jsondata")
    }
}
export async function GetDiamondDB(){
    const db = await openDB(dbName, version, {
        upgrade(db, oldVersion, newVersion, transaction) {
          const store = db.createObjectStore(storeName)
        }
      })
      const getkeys = await db.transaction(storeName,"readwrite").objectStore(storeName).get("jsondata")

      return getkeys
}