import React,{useState} from "react";
import { Field } from "formik";


export const FluorescenceLab =({formik,labData,language}) =>{
    const [flouData,setFlouData] =useState(labData.length !== 0 && labData[0].lab_fluorescence !== null ? labData[0].lab_fluorescence : [])

    // const flourData = ([
    //     {
    //         id: 1,
    //         value:"NON",
    //         flour:"NONE"
    //     },
    //     {
    //         id: 2,
    //         value:"FNT",
    //         flour:"FAINT"
    //     },
    //     {
    //         id: 3,
    //         value:"VSLT",
    //         flour:"VERY SLIGHT"
    //     },
    //     {
    //         id: 4,
    //         value:"SLT",
    //         flour:"SLIGHT"
    //     },
    //     {
    //         id: 5,
    //         value:"MED",
    //         flour:"MEDIUM"
    //     },
    //     {
    //         id: 6,
    //         value:"STG",
    //         flour:"STRONG"
    //     },
    //     {
    //         id: 7,
    //         value:"VST",
    //         flour:"VERY STRONG"
    //     }
    // ]);

    const newFlouData =flouData.length!==0 ? flouData.split(',').map((value,index) => {
        return {
            id: index,
            value:value,
            flour: value
            // flour: value === "NON" ? "NONE" :
            //     value === "FNT" ? "FAINT" :
            //     value === "VSLT" ? "VERY SLIGHT" :
            //     value === "SLT" ? "SLIGHT" :
            //     value === "MED" ? "MEDIUM" :
            //     value === "STG" ? "STRONG" :
            //     value === "VST" ? "VERY STRONG" :
            //     value  
        }
    }):null
    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_LIST_FLUORESCENCE}</div>
                <div className="col-12" >
                    {newFlouData? newFlouData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Fluorescence_Intensity" id={`${item.flour}_flour`} value={item.value}/>
                            <div className="wClarityBox selectBox">
                                <span>{item.flour}</span>
                            </div>
                        </label>
                    )):null}
                </div>
            </div>
        </>
    );
}