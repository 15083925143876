import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function ListSkeleton() {
    return (
        <>
                        {Array.from(Array(17), (e, i) => {
                            return (
                                <tr key={i}>
                                    <td><Skeleton className="mr-3" variant="rect" width={140} height={30} /></td>
                                    <td className="text-center">
                                        <div className="d-flex align-items-center justify-content-center">
                                            {Array.from(Array(3), (e, i) => {
                                                return (
                                                    <Skeleton className="mr-3" variant="text" width={20} height={20} />
                                                );
                                            })}
                                        </div>
                                    </td>
                                    <td className="">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <Skeleton className="mr-3" variant="text" width={80} height={20} key={i}/>
                                        </div>
                                    </td>
                                    {Array.from(Array(8), (e, i) => {
                                        return (
                                            <td key={i}><Skeleton className="mx-auto" variant="text" width={30} height={20} /></td>
                                        );
                                    })}
                                    <td key={i}><Skeleton className="mx-auto" variant="text" width={100} height={20} /></td>
                                    <td><Skeleton className="mx-auto" variant="text" width={130} height={20} /></td>
                                    <td><Skeleton className="mx-auto" variant="text" width={130} height={20} /></td>
                                </tr>
                            );
                        })}
        </>
    );
}
