/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect,useRef} from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { Basic } from "./searchComponent/Basic";
import { Advanced } from "./searchComponent/Advanced";
import PropTypes from "prop-types";
import axios from "axios";
import {NODE_API_URL} from "../../../../env_config"
import {
    Backdrop,
    CircularProgress,
    Typography,
    makeStyles,
    Snackbar,
    SnackbarContent
} from "@material-ui/core";
import clsx from "clsx";
import amber from "material-ui/colors/amber";
import Cookies from "js-cookie";
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
    
    textField: {
      width: 400,
      backgroundColor: '#F3F6F9',
    },
    dense: {
        color: '#000000b8'
    },
    success: {
        backgroundColor: amber[500],
    },
  }));

export const SearchDiamondTheme1 = ({ruleData,naturalData,naturalFancyData,labData,labFancyData,language,themeData,ipData }) => {
    const formikRef = useRef();
    const [openLoader, setOpenLoader] = useState(false);
    const [searchdata,setSearchData] = useState([])
    const [alertOpen,setAlertOpen] = useState(false)
    const [diamond_type,setDiamondType] = useState()
    const [custompage] = useState(1)
    const [loader,setLoader] = useState(false)
    const [sorting] = useState("")
    const [value] = useState(window.glob||0);
    const [stoneCount, setStoneCount] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fancy,setFancy] = useState(naturalData.length===0 && naturalFancyData.length !== 0 ? true : false)
    const [fancyLab,setFancyLab] = useState(labData.length===0 && labFancyData.length !== 0 ? true : false)
    const history = useHistory();

    const classes = useStyles();

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }

    function handleClose(event, reason) {
        setAlertOpen(false);
    }

    useEffect(() => {
        if(naturalData && naturalData.length && naturalData[0].status === 0){
            setFancy(true)
        }
        if(labData && labData.length && labData[0].status === 0){
            setFancyLab(true)
        }
    },[])

    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(!openLoader);
    };

    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
    };


    useEffect(() =>{
        if(value===0){
            // setDiamondType("N")
            if((naturalData.length !== 0 && naturalData[0].status !== 0) || (naturalFancyData.length !== 0 && naturalFancyData[0].status !== 0)){
                setDiamondType("N")
            }
            else if((labData.length !== 0 && labData[0].status !== 0)|| (labFancyData.length !== 0 && labFancyData[0].status !== 0)){
                setDiamondType("L")
            }
        }
        if(value===1){
            // setDiamondType("L")
            if((naturalData.length !== 0 && naturalData[0].status !== 0) || (naturalFancyData.length !== 0 && naturalFancyData[0].status !== 0)){
                setDiamondType("N")
            }
            else if((labData.length !== 0 && labData[0].status !== 0)|| (labFancyData.length !== 0 && labFancyData[0].status !== 0)){
                setDiamondType("L")
            }
        }
        
    }, []);
   
    // natural ---------------------------------------------
    function handleSearch(values) {
        const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
        let FinalObject = {
            page:custompage,
            user_id:localStorage.getItem("user_id"),
            diamond_type:diamond_type,
            token:localStorage.getItem("auth"),
        }

        if(values.Shape && values.Shape.length){
            FinalObject["shape"] = values.Shape
        }
        if(values.Cut && values.Cut.length){
            FinalObject["cut"] = values.Cut
        }
        if(values.Clarity && values.Clarity.length){
            FinalObject["clarity"] = values.Clarity
        }
        if(values.Color && values.Color.length){
            FinalObject["color"] = values.Color
        }
        if(fancy && diamond_type === "N"){
            FinalObject["fancy_color_diamond"] = "YES"
        }
        if(fancyLab && diamond_type === "L"){
            FinalObject["fancy_color_diamond"] = "YES"
        }
        if(values.Fancy_Color && values.Fancy_Color.length){
            FinalObject["fancy_color"] = values.Fancy_Color
        }
        if(values.Fancy_Color_Overtone && values.Fancy_Color_Overtone.length){
            FinalObject["fancy_overtone"] = values.Fancy_Color_Overtone
        }
        if(values.Fancy_Color_Intensity && values.Fancy_Color_Intensity.length){
            FinalObject["fancy_intensity"] = values.Fancy_Color_Intensity
        }
        if(values.Lab && values.Lab.length){
            FinalObject["lab"] = values.Lab
        }
        if(values.Polish && values.Polish.length){
            FinalObject["polish"] = values.Polish
        }
        if(values.Symmetry && values.Symmetry.length){
            FinalObject["symmetry"] = values.Symmetry
        }
        if(values.Fluorescence_Intensity && values.Fluorescence_Intensity.length){
            FinalObject["fluorescence"] = values.Fluorescence_Intensity
        }
        if(values.minCarat >= 0){
            FinalObject["min_carat"] = values.minCarat
        }
        if(values.maxCarat >= 0){
            FinalObject["max_carat"] = values.maxCarat
        }
        if(values.minPrice >= 0){
            FinalObject["total_price_from"] = values.minPrice
        }
        if(values.maxPrice >= 0){
            FinalObject["total_price_to"] = values.maxPrice
        }

        if(values.minDepth >= 0){
            FinalObject["depthmin"] = values.minDepth
        }
        if(values.maxDepth >= 0){
            FinalObject["depthmax"] = values.maxDepth
        }
        if(values.minTable >= 0){
            FinalObject["tablemin"] = values.minTable
        }
        if(values.maxTable >= 0){
            FinalObject["tablemax"] = values.maxTable
        }
        if(values.minRatio >= 0){
            FinalObject["ratiomin"] = values.minRatio
        }
        if(values.maxRatio >= 0){
            FinalObject["ratiomax"] = values.maxRatio
        }
        if(sorting === "carat_desc"){
            FinalObject["caratfilter"] = "dsc"
        }
        if(sorting === "carat_asc"){
            FinalObject["caratfilter"] = "asc"
        }
        if(sorting === "price_desc"){
            FinalObject["pricefilter"] = "dsc"
        }
        if(sorting === "price_asc"){
            FinalObject["pricefilter"] = "asc"
        }
        if(sorting === "clarity_desc"){
            FinalObject["clarityfilter"] = "dsc"
        }
        if(sorting === "clarity_asc"){
            FinalObject["clarityfilter"] = "asc"
        }
        if(sorting === "color_desc"){
            FinalObject["colorfilter"] = "dsc"
        }
        if(sorting === "color_asc"){
            FinalObject["colorfilter"] = "asc"
        }
        if(values.Min_Carat >= 0){
            FinalObject["min_carat"] = values.Min_Carat
        }
        if(values.Max_Carat >= 0){
            FinalObject["max_carat"] = values.Max_Carat
        }
        if(values.Min_Price >= 0){
            FinalObject["total_price_from"] = values.Min_Price
        }
        if(values.Max_Price >= 0){
            FinalObject["total_price_to"] = values.Max_Price
        }
        if(values.Min_Table_Prc >= 0){
            FinalObject["tablemin"] = values.Min_Table_Prc
        }
        if(values.Max_Table_Prc >= 0){
            FinalObject["tablemax"] = values.Max_Table_Prc
        }
        if(values.Min_Depth_Prc >= 0){
            FinalObject["depthmin"] = values.Min_Depth_Prc
        }
        if(values.Max_Depth_Prc >= 0){
            FinalObject["depthmax"] = values.Max_Depth_Prc
        }
        if(values.Min_Ratio >= 0){
            FinalObject["ratiomin"] = values.Min_Ratio
        }
        if(values.Max_Ratio >= 0){
            FinalObject["ratiomax"] = values.Max_Ratio
        }
        setLoader(true)
        let len = Object.keys(FinalObject).length;
            const temp = {...FinalObject};
            let data = "";
            for (let key in temp) {
                len--;
                let arr = temp[key];
                if (len) {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                    }
                } else {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                    }
                }
            }
            const finalData = `{ "JsonData":  [${data}] , "CustomerId": ${52027}, "WLDiamondType": "${diamond_type}" }`;
            // axios({
            //     method: "POST",
            //     url: SEARCH_LOG,
            //     headers: { "Content-Type": "application/json" },
            //     data: finalData,
            // })
            //     .then(function(res) {
            //     })
            //     .catch(function(res) {
            //     });
            let LogObj = {...FinalObject}
            delete LogObj.token
            if(LogObj.fancy_color_diamond === "YES"){
                delete LogObj.color
            }else{
                delete LogObj.fancy_color
                delete LogObj.fancy_intensity
                delete LogObj.fancy_intensity
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/iframe/storeUserACtivity`,
                headers: { 
                    "Content-Type": "application/json",
                    "Parent-Domain":parentDomain
                //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                 },
                data: {
                    user_id:FinalObject.user_id,
                    activity_type:"IFrame Search",
                    user_agent:navigator.userAgent,
                    referrer:parentDomain,
                    activity_data:LogObj,
                    ip_address:ipData.query,
                    token:localStorage.getItem("auth")
                }
            }).then(response => {
            }).catch(error => {
            })
            if(diamond_type === "N"){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/iframe/fetchiFrameNatStone`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain
                     },
                    data: FinalObject,
                }).then(res => {
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        setSearchData(res.data)
                        setStoneCount(JSON.stringify(res.data.total));
                        history.push({
                            pathname: "/grid",
                            state: {
                                diamonddata:res.data.data,
                                diamondparams:FinalObject
                            },
                        });
                    }
                    else{
                        disableLoading()
                        if(res.data.message==="No Records Found"){
                            setAlertOpen(true)
                        }
                    }
                }).catch(err => {
                    setLoader(false)
                })
            }
            if(diamond_type === "L"){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/iframe/fetchiFrameLabStone`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain
                     },
                    data: FinalObject,
                }).then(res => {
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        setSearchData(res.data)
                        setStoneCount(JSON.stringify(res.data.total));
                        history.push({
                            pathname: "/grid",
                            state: {
                                diamonddata:res.data.data,
                                diamondparams:FinalObject
                            },
                        });
                    }
                    else{
                        if(res.data.message==="No Records Found"){
                            setAlertOpen(true)
                        }
                        disableLoading()
                    }
                }).catch(err => {
                    setLoader(false)
                })
            }
    }

    const [finalFetchData,] = useState([]);
    useEffect(
        () => window.localStorage.setItem("fetchData", finalFetchData),
        [finalFetchData]
    );
    useEffect(
        () => window.localStorage.setItem("stoneCount", stoneCount),
        [stoneCount]
    );

    return (
        <div className="container theme1">
            <div className={`row ${diamond_type==="N"?"natTab":"labTab"}`}>
                <div className="col-12">
                    <div className="card fiter-card card-custom card-sticky gutter-b">
                        {/* <div
                            className="card-header minHeight justify-content-start p-0"
                            style={{
                                height: " 49px!important",
                            }}
                        >
                            <Paper position="static">
                            <Tabs value={diamond_type} onChange={handleChange}>
                                    {(naturalData.length !== 0 && naturalData[0].status !== 0) || (naturalFancyData.length !== 0 && naturalFancyData[0].status !== 0) ? <Tab label={language.CCMODE_THEME1_SEARCHDIAMOND_NATURAL} value="N" onClick={() => formikRef.current?.resetForm()}/>:""}
                                    {(labData.length !== 0 && labData[0].status !== 0)|| (labFancyData.length !== 0 && labFancyData[0].status !== 0)  ? <Tab label={language.CCMODE_THEME1_SEARCHDIAMOND_LABGROWN} value="L" onClick={() => formikRef.current?.resetForm()}/>:""}
                                </Tabs>
                            </Paper>
                        </div> */}
                            <Formik
                                innerRef={formikRef}
                                enableReinitialize = {true}
                                initialValues={window.Nat||{}}
                                onSubmit={async (values) => {
                                    enableLoading();
                                    Object.keys(values).forEach(key => {
                                        if (values[key] === '' || values[key] === null || values[key].length === 0) {
                                                 delete values[key];
                                          }
                                        });
                                    setTimeout(() => {
                                        handleSearch(values);
                                    }, 1000);
                                }}
                            >
                                {(formik, isSubmitting, handleSubmit) => (
                                    <>
                                        <Form>
                                            <div className="card-body">
                                                <div className="row">
                                                    {/* data: {finalNaturalData} */}
                                                    <div className="col-12 text-center mb-5">
                                                        {
                                                            (naturalData.length !== 0 && naturalData[0].status !== 0) ||(naturalFancyData.length !== 0 && naturalFancyData[0].status !== 0) ? 
                                                                <div className="custom-result-box">{language.FILTER_LABEL_NATURAL}</div>
                                                            : null 
                                                        }
                                                        {
                                                            (labData.length !== 0 && labData[0].status !== 0)|| (labFancyData.length !== 0 && labFancyData[0].status !== 0) ? 
                                                                <div className="custom-result-box">{language.FILTER_LABEL_LAB}</div> 
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="col-12 mb-10">
                                                        <Basic formik={formik} setSearchData={setSearchData} diamond_type={diamond_type} custompage={custompage} setLoader={setLoader} sorting={sorting} ruleData={ruleData} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} setFancy={setFancy} fancy={fancy} setFancyLab={setFancyLab} fancyLab={fancyLab} language={language} themeData={themeData}/>
                                                    </div>
                                                    {/* <div className="col-12 mb-10">
                                                        <Preference />
                                                    </div> */}
                                                    <div className="col-12 mb-10">
                                                        <Advanced formik={formik} setSearchData={setSearchData} diamond_type={diamond_type} custompage={custompage} setLoader={setLoader} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} fancy={fancy} fancyLab={fancyLab} language={language} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="card-footer natTab text-center sticky-top"
                                                style={{ bottom: "0" }}
                                            >
                                                
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary px-10 font-weight-bold btn-square mr-2 btn-lg"
                                                    disabled={isSubmitting}
                                                    onClick={handleSubmit}
                                                >
                                                    {isSubmitting
                                                        ? language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE_PLEASEWAIT
                                                        : language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE_SEARCH}
                                                    {loading && (
                                                        <span className="ml-3 spinner spinner-white"></span>
                                                    )}
                                                </button>
                                                <Backdrop
                                                    sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                                                    open={openLoader}
                                                    // onClick={handleCloseLoader}
                                                >
                                                    <CircularProgress color="secondary" />
                                                </Backdrop>
                                                <button
                                                    type="reset"
                                                    className="btn font-weight-bold btn-square btn-warning btn-lg px-10"
                                                >
                                                    {language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE_RESET}
                                                </button>
                                                
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                    </div>
                </div>
                <div className="col-12 d-none"></div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={alertOpen}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={`No Diamonds Found`}
                />
            </Snackbar>
        </div>
    );
};
