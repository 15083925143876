import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function GridSkeleton() {
    return (
        <>
             <div className="d-flex flex-wrap justify-content-between gridData mt-10">
                        {Array.from(Array(12), (e, i) => {
                            return (
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5" key={i}>
                                    <div className="row m-0 card gridData justify-content-center">
                                        <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200" style={{height:"280px"}} >
                                            <Skeleton variant="rect" height={280} width={280} />
                                        </div>
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="text" width={30} />
                                            <Skeleton variant="text" width={20} />
                                        </div>
                                        <div className="col-12  d-flex justify-content-center flex-column align-items-center">
                                            <Skeleton variant="text" width={220} height={30}/>
                                            <hr className="w-75 m-1 mt-5 px-5 pt-2 pb-0 my-auto" />
                                        </div>
                                        
                                        <div className="col-12  d-flex justify-content-center flex-column align-items-center">
                                            <Skeleton variant="text" width={100}  height={45}/>
                                            <hr className="w-75 m-1 px-5 py-2" />
                                        </div>
                                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                            <Skeleton variant="rect" width={100} height={30} />
                                            <Skeleton variant="rect" width={100} height={30}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
        </>
    );
}
